import Icon from '@/components/icon/Icon';

export const GradeIconMap = new Map([
  ['NONE', Icon.BULLSEYE],
  ['1', Icon.GRADE_NG],
  ['2', Icon.GRADE_U],
  ['3', Icon.GRADE_F],
  ['4', Icon.GRADE_G],
  ['5', Icon.GRADE_E]
]);
