import { GetterTree } from 'vuex';
import { EventState } from './types';
import { RootState } from '../types';
import { GradedEvent, GradedManeuver } from '@/models';

export const getters: GetterTree<EventState, RootState> = {
  syllabusEvents(state): Event[] {
    return state.syllabusEvents;
  },
  syllabusEvent(state): Event {
    return state.syllabusEvent;
  },
  unlockedEvents(state): GradedEvent[] {
    return state.unlockedEvents;
  },
  scheduledEvents(state): GradedEvent[] {
    return state.scheduledEvents;
  },
  maneuvers(state): GradedManeuver[] {
    return state.maneuvers;
  },
  syllabusAcademicEvents(state): Event[] {
    return state.syllabusAcademicEvents;
  }
};
