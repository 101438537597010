import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { CourseState } from './types';
import { Api } from '@/api';
import Course from '../../models/Course';

export const state: CourseState = {
  currentCourse: {} as Course,
  allCourses: [] as Course[]
};

export function create(api: Api): Module<CourseState, RootState> {
  const actions = createActions(api);
  const applicantModule: Module<CourseState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return applicantModule;
}
