import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { UserState } from './types';
import { RootState } from '../types';
import { Api } from '@/api';

export const state: UserState = {
  currentUser: null,
  isLoggedIn: false,
  isEmailVerified: false,
  error: false,
  firebaseUser: null
};

export function create(api: Api): Module<UserState, RootState> {
  const actions = createActions(api);
  const userModule: Module<UserState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return userModule;
}
