import firebase from 'firebase/app';
import 'firebase/storage';
import { buildConfig } from '../config';

const config = buildConfig(process.env);

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

export const storage = firebase.storage();

if (config.firebase.storage.local) {
  storage.useEmulator(
    config.firebase.storage.host,
    config.firebase.storage.port
  );
}

export default class StorageService {
  storage: firebase.storage.Storage;
  storageReference: firebase.storage.Reference;
  constructor() {
    this.storage = storage;
    this.storageReference = this.storage.ref();
  }

  async getImage(id: string): Promise<unknown> {
    const reference = this.storageReference.child(`images/${id}`);
    return reference.getDownloadURL();
  }

  public async fetchSyllabusDownloadURL(
    syllabusFileName: string
  ): Promise<string> {
    const downloadURL = await this.storage
      .ref(`${syllabusFileName}.json`)
      .getDownloadURL();
    return downloadURL;
  }
}
