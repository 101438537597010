import { ActionTree, Commit } from 'vuex';
import { CourseState } from './types';
import { RootState } from '../types';
import { Api } from '@/api';
import Course from '../../models/Course';
import { ErrorResponse, SuccessResponse } from '../../api/types';
import { MutationTypes } from '../student/mutations';

function typedCommit(
  commit: Commit,
  mutationType: MutationTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) {
  return commit(mutationType, payload);
}

export function createActions(api: Api): ActionTree<CourseState, RootState> {
  const actions: ActionTree<CourseState, RootState> = {
    setCurrentCourse({ commit }, course: Course): Course {
      typedCommit(commit, 'SET_CURRENT_COURSE', course);
      return course;
    },
    async fetchCurrentCourse(
      { commit },
      courseId: string
    ): Promise<SuccessResponse<Course> | ErrorResponse> {
      const apiResponse = await api.fetchCourse(courseId);

      if (apiResponse.constructor === SuccessResponse) {
        typedCommit(commit, 'SET_CURRENT_COURSE', apiResponse.data);
        return apiResponse;
      } else {
        return apiResponse;
      }
    }
  };
  return actions;
}
