import { MutationTree } from 'vuex';
import { SyllabusState } from './types';
import { Chapter, Syllabus, Topic } from '@/models';

export const mutations: MutationTree<SyllabusState> = {
  SET_CURRENT_COURSE(state, course) {
    state.currentCourse = course;
  },
  SET_SYLLABUS(state, syllabusEvents: Syllabus) {
    state.syllabus = syllabusEvents;
  },
  SET_CHAPTER(state, chapter: Chapter) {
    state.chapter = chapter;
  },
  SET_TOPIC(state, topic: Topic) {
    state.topic = topic;
  }
};
