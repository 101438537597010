
import { computed, defineComponent, PropType } from 'vue';
import Icon from './Icon';

export const IconMap: Record<Icon, string> = {
  [Icon.NONE]: require('../../assets/icons/bullseye.svg'),
  [Icon.BULLSEYE]: require('../../assets/icons/bullseye.svg'),
  [Icon.BOOK_INFO]: require('../../assets/icons/book_info.svg'),
  [Icon.CALENDAR]: require('../../assets/icons/calendar.svg'),
  [Icon.CHART]: require('../../assets/icons/chart.svg'),
  [Icon.CHECK]: require('../../assets/icons/check.svg'),
  [Icon.CHEVRON_DOWN]: require('../../assets/icons/chevron_down.svg'),
  [Icon.CHEVRON_RIGHT]: require('../../assets/icons/chevron_right.svg'),
  [Icon.CHEVRON_UP_LIGHT]: require('../../assets/icons/chevron_up_light.svg'),
  [Icon.DOUBLE_CHEVRON_DOWN]: require('../../assets/icons/double_chevron_down.svg'),
  [Icon.DOUBLE_CHEVRON_UP]: require('../../assets/icons/double_chevron_up.svg'),
  [Icon.DOUBLE_CHEVRON_LEFT]: require('../../assets/icons/double_chevron_left.svg'),
  [Icon.DOUBLE_CHEVRON_RIGHT]: require('../../assets/icons/double_chevron_right.svg'),
  [Icon.DOCUMENT]: require('../../assets/icons/document.svg'),
  [Icon.FUNCTION]: require('../../assets/icons/function.svg'),
  [Icon.PLAN]: require('../../assets/icons/plan.svg'),
  [Icon.PEOPLE]: require('../../assets/icons/people.svg'),
  [Icon.PEOPLE_STAR]: require('../../assets/icons/people_star.svg'),
  [Icon.COHORTS]: require('../../assets/icons/cohorts.svg'),
  [Icon.LOADING]: require('../../assets/icons/loading.svg'),
  [Icon.LOGO]: require('../../assets/icons/logo.svg'),
  [Icon.SEARCH]: require('../../assets/icons/search.svg'),
  [Icon.TREND_UP]: require('../../assets/icons/trend-up.svg'),
  [Icon.TREND_DOWN]: require('../../assets/icons/trend-down.svg'),
  [Icon.TREND_NONE]: require('../../assets/icons/trend-none.svg'),
  [Icon.GRADE_E]: require('../../assets/icons/grade-E.svg'),
  [Icon.GRADE_F]: require('../../assets/icons/grade-F.svg'),
  [Icon.GRADE_G]: require('../../assets/icons/grade-G.svg'),
  [Icon.GRADE_NG]: require('../../assets/icons/grade-NG.svg'),
  [Icon.GRADE_U]: require('../../assets/icons/grade-U.svg'),
  [Icon.WINGS]: require('../../assets/icons/wings.svg'),
  [Icon.TABLE]: require('../../assets/icons/table.svg'),
  [Icon.RECOMMENDATION]: require('../../assets/icons/recommendation.svg'),
  [Icon.CARDINALITY]: require('../../assets/icons/bullseye.svg'),
  [Icon.RED_CROSS]: require('../../assets/icons/red-cross.svg'),
  [Icon.RED_CRITICAL]: require('../../assets/icons/full_red_critical.svg'),
  [Icon.BLUE_CHECK]: require('../../assets/icons/blue_check.svg'),
  [Icon.HALF_RED_CHECK]: require('../../assets/icons/half_red_check.svg'),
  [Icon.UNKNOWN]: require('../../assets/icons/unknown.svg'),
  [Icon.EMPTY]: require('../../assets/icons/empty.svg')
};

export default defineComponent({
  props: {
    icon: {
      type: Number as PropType<Icon>,
      required: true
    }
  },
  setup(props) {
    const src = computed(() => {
      return IconMap[props.icon];
    });
    return {
      src
    };
  }
});
