import { Api } from '@/api';
import {
  CreateGradedManeuverViewModel,
  GradedEvent,
  GradedManeuver,
  ManeuverViewModel,
  Recommendation,
  Student
} from '@/models';
import firebase from 'firebase/app';
import { ActionTree, Commit } from 'vuex';
import { getDetailedRecommendations } from '../../api/Recommendation';
import { ErrorResponse, Errors, SuccessResponse } from '../../api/types';
import Reason from '../../models/Reason';
import { RootState } from '../types';
import { MutationTypes } from './mutations';
import { StudentState } from './types';

const errorWaitTimeout = 5250;
const simulatedLoadingTimeout = 700;
let isSavingEventTimer: number;
let isSavingManeuverTimer: number;

function typedCommit(
  commit: Commit,
  mutationType: MutationTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) {
  return commit(mutationType, payload);
}

function setGradedEventAsLoading(state: StudentState, eventId: string) {
  const index = state.studentEvents.findIndex((e) => e.id === eventId);
  const foundEvent = state.studentEvents[index];
  foundEvent.loading = true;
  state.studentEvents[index] = foundEvent;
}

function setCreateManeuverAsLoading(
  state: StudentState,
  maneuver: CreateGradedManeuverViewModel
) {
  const eventIndex = state.studentEvents.findIndex(
    (e) => e.id === maneuver.eventId
  );
  const foundEvent = state.studentEvents[eventIndex];

  foundEvent.maneuvers.push({
    ...maneuver,
    id: 'skeleton',
    loading: true
  } as GradedManeuver);

  state.studentEvents[eventIndex] = foundEvent;
}

function setGradedManeuverAsLoading(
  state: StudentState,
  maneuver: ManeuverViewModel
) {
  const eventIndex = state.studentEvents.findIndex(
    (e) => e.id === maneuver.eventId
  );
  const foundEvent = state.studentEvents[eventIndex];
  const maneuverIndex = foundEvent.maneuvers.findIndex(
    (m) => m.name === maneuver.name
  );
  const foundManeuver = foundEvent.maneuvers[maneuverIndex];
  if (foundManeuver) {
    foundManeuver.loading = true;
  } else {
    foundEvent.maneuvers.push(
      GradedManeuver.fromJson({
        ...maneuver,
        id: '1',
        grade: 'NONE',
        reason: Reason.fromJson({ display: '', key: '' })
      })
    );
  }

  state.studentEvents[eventIndex] = foundEvent;
}

export function createActions(api: Api): ActionTree<StudentState, RootState> {
  const actions: ActionTree<StudentState, RootState> = {
    async fetch(
      { commit },
      id: string
    ): Promise<SuccessResponse<Student> | ErrorResponse> {
      typedCommit(commit, 'SET_STUDENT_REQUEST', {
        loading: true
      });
      const apiResponse = await api.fetchStudent(id);
      return new Promise((resolve) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            typedCommit(commit, 'SET_STUDENT_REQUEST', {
              student: apiResponse.data
            });
            typedCommit(commit, 'SET_STUDENT', apiResponse.data);
            resolve(apiResponse);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            resolve(apiResponse);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async fetchAll({
      commit
    }): Promise<SuccessResponse<Student[]> | ErrorResponse> {
      typedCommit(commit, 'SET_STUDENTS_REQUEST', {
        loading: true
      });
      const apiResponse = await api.fetchStudents();
      return new Promise((resolve) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            typedCommit(commit, 'SET_STUDENTS_REQUEST', {
              students: apiResponse.data
            });
            typedCommit(commit, 'SET_STUDENTS', apiResponse.data);
            resolve(apiResponse);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            resolve(apiResponse);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async fetchStudentGradedEventsFullyQualified(
      { commit },
      payload: {
        studentId: string;
        filterByPhase: string | null;
        toggleSpinner: boolean;
      }
    ): Promise<GradedEvent[]> {
      if (payload.toggleSpinner) {
        typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
          loading: true
        });
      }
      const apiResponse = await api.fetchStudentGradedEventsFullyQualified(
        payload.studentId,
        payload.filterByPhase
      );
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              events: apiResponse.data
            });
            typedCommit(commit, 'SET_STUDENT_EVENTS', apiResponse.data);
            resolve(apiResponse.data);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async fetchEvent(
      { commit },
      { studentId, eventId }
    ): Promise<GradedEvent | undefined> {
      const apiResponse = await api.fetchStudentEvent(studentId, eventId);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENT', apiResponse.data);
            resolve(apiResponse.data);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async createEvent({ commit }, { studentId, event }): Promise<string> {
      const loadingEvent = event;
      loadingEvent.loading = true;
      typedCommit(commit, 'ADD_STUDENT_EVENTS', loadingEvent);

      const apiResponse = await api.createStudentEvent(studentId, event);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(apiResponse.data.id);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async updateEvent(
      { commit, state },
      { studentId, event }
    ): Promise<boolean> {
      setGradedEventAsLoading(state, event.id);
      const apiResponse = await api.setGradedEvent(studentId, event);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(true);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async deleteEvent(
      { commit, state },
      { studentId, event }
    ): Promise<boolean> {
      setGradedEventAsLoading(state, event.id);
      const apiResponse = await api.deleteGradedEvent(studentId, event);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(true);
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async updateEventWithoutFetchingFullyQualified(
      { commit, dispatch },
      { studentId, event }
    ): Promise<void> {
      typedCommit(commit, 'SET_IS_SAVING_EVENT', true);
      const apiResponse = await api.setGradedEvent(studentId, event);
      clearTimeout(isSavingEventTimer);
      isSavingEventTimer = setTimeout(() => {
        typedCommit(commit, 'SET_IS_SAVING_EVENT', false);
      }, simulatedLoadingTimeout);

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(dispatch('fetchEvent', { studentId, eventId: event.id }));
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async updateEventManeuver(
      { commit, dispatch },
      {
        studentId,
        eventId,
        maneuver
      }: { studentId: string; eventId: string; maneuver: GradedManeuver }
    ): Promise<void> {
      typedCommit(commit, 'SET_IS_SAVING_MANEUVER', true);
      const apiResponse = await api.setGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      clearTimeout(isSavingManeuverTimer);
      isSavingManeuverTimer = setTimeout(() => {
        typedCommit(commit, 'SET_IS_SAVING_MANEUVER', false);
      }, simulatedLoadingTimeout);

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(dispatch('fetchEvent', { studentId, eventId }));
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async createEventManeuver(
      { commit, dispatch },
      {
        studentId,
        eventId,
        maneuver
      }: {
        studentId: string;
        eventId: string;
        maneuver: CreateGradedManeuverViewModel;
      }
    ): Promise<
      firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    > {
      typedCommit(commit, 'SET_IS_SAVING_MANEUVER', true);
      const apiResponse = await api.addGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      clearTimeout(isSavingManeuverTimer);
      isSavingManeuverTimer = setTimeout(() => {
        typedCommit(commit, 'SET_IS_SAVING_MANEUVER', false);
      }, simulatedLoadingTimeout);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(dispatch('fetchEvent', { studentId, eventId }));
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async deleteEventManeuver(
      { dispatch, state, commit },
      {
        studentId,
        eventId,
        maneuver
      }: {
        studentId: string;
        eventId: string;
        maneuver: ManeuverViewModel;
      }
    ): Promise<boolean> {
      const apiResponse = await api.deleteGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(
              dispatch('fetchStudentGradedEventsFullyQualified', {
                studentId,
                filterByPhase: state.studentEventsQuery.filterByPhase
              })
            );
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async addEventManeuverCollection(
      { dispatch, state, commit },
      {
        studentId,
        maneuvers
      }: {
        studentId: string;
        maneuvers: CreateGradedManeuverViewModel[];
      }
    ): Promise<GradedEvent[]> {
      maneuvers.forEach((maneuverViewModel: CreateGradedManeuverViewModel) => {
        typedCommit(commit, 'ADD_MANEUVER_GRADE', {
          maneuver: maneuverViewModel,
          eventId: maneuverViewModel.eventId
        });
        setCreateManeuverAsLoading(state, maneuverViewModel);
      });

      const apiResponse = await api.addGradedEventManeuverCollection(
        studentId,
        maneuvers
      );

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(
              dispatch('fetchStudentGradedEventsFullyQualified', {
                studentId,
                filterByPhase: state.studentEventsQuery.filterByPhase
              })
            );
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async updateEventManeuverCollection(
      { dispatch, state, commit },
      {
        studentId,
        maneuvers
      }: {
        studentId: string;
        maneuvers: ManeuverViewModel[];
      }
    ): Promise<GradedEvent[]> {
      maneuvers.forEach((maneuverViewModel: ManeuverViewModel) => {
        setGradedManeuverAsLoading(state, maneuverViewModel);
      });

      const apiResponse = await api.updateEventManeuverCollection(
        studentId,
        maneuvers
      );

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(
              dispatch('fetchStudentGradedEventsFullyQualified', {
                studentId,
                filterByPhase: state.studentEventsQuery.filterByPhase
              })
            );
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async deleteEventManeuverCollection(
      { dispatch, state, commit },
      {
        studentId,
        maneuvers
      }: {
        studentId: string;
        maneuvers: ManeuverViewModel[];
      }
    ): Promise<GradedEvent[]> {
      maneuvers.forEach((maneuverViewModel: ManeuverViewModel) => {
        setGradedManeuverAsLoading(state, maneuverViewModel);
      });

      const apiResponse = await api.deleteGradedEventManeuverCollection(
        studentId,
        maneuvers
      );
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(
              dispatch('fetchStudentGradedEventsFullyQualified', {
                studentId,
                filterByPhase: state.studentEventsQuery.filterByPhase
              })
            );
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async updateEventCollection(
      { dispatch, state, commit },
      { studentId, events }
    ): Promise<boolean> {
      events.forEach((event: GradedEvent) => {
        setGradedEventAsLoading(state, event.id);
      });
      const apiResponse = await api.updateEventCollection(studentId, events);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (apiResponse.constructor === SuccessResponse) {
            resolve(
              dispatch('fetchStudentGradedEventsFullyQualified', {
                studentId,
                filterByPhase: state.studentEventsQuery.filterByPhase
              })
            );
          } else if (apiResponse.constructor === ErrorResponse) {
            typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
              error: apiResponse.error
            });
            setTimeout(() => {
              typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
                error: null
              });
            }, errorWaitTimeout);
            reject(apiResponse.error);
          }
        }, simulatedLoadingTimeout);
      });
    },
    async fetchRecommendations({ commit, state }): Promise<Recommendation[]> {
      const recommendations = getDetailedRecommendations(state.studentEvents);
      typedCommit(commit, 'SET_STUDENT_RECOMMENDATIONS', recommendations);
      return recommendations;
    },
    setFilterByPhase({ commit }, phase: string): void {
      typedCommit(commit, 'SET_FILTER_BY_PHASE', phase);
    },
    setErrorMessage(
      { commit },
      payload: { type: Errors; message: string }
    ): void {
      typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
        error: payload.message
      });
      setTimeout(() => {
        typedCommit(commit, 'SET_STUDENT_EVENTS_REQUEST', {
          error: null
        });
      }, errorWaitTimeout);
    }
  };
  return actions;
}
