import { plainToClass } from 'class-transformer';
import { Event } from '@/models';

export class Topic {
  title = '';
  events: Event[] = [];

  // eslint-disable-next-line
  static fromJson(payload?: any): Topic {
    return plainToClass(Topic, payload);
  }
}
