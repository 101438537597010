
import '@/assets/styles/index.css';
import NavigationComponent from '@/components/NavigationComponent.vue';
import 'reflect-metadata';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import ErrorAlert from './components/ErrorAlert.vue';

export default defineComponent({
  components: {
    NavigationComponent,
    ErrorAlert
  },
  setup() {
    const store = useStore();

    const isLoggedIn = computed(() => store.getters['userModule/isLoggedIn']);
    const currentUser = computed(() => store.getters['userModule/currentUser']);
    const isEmailVerified = computed(
      () => store.getters['userModule/isEmailVerified']
    );

    // needs to be refactored to probably have a global error state so we only check that one state object
    const studentRequest = computed(
      () => store.getters['studentModule/studentRequest']
    );

    const studentsRequest = computed(
      () => store.getters['studentModule/studentsRequest']
    );

    const studentEventsRequest = computed(
      () => store.getters['studentModule/studentEventsRequest']
    );

    const error = computed(() => {
      if (studentRequest.value.error) {
        return studentRequest.value.error;
      } else if (studentsRequest.value.error) {
        return studentsRequest.value.error;
      } else if (studentEventsRequest.value.error) {
        return studentEventsRequest.value.error;
      } else {
        return null;
      }
    });

    return {
      currentUser,
      error,
      isEmailVerified,
      isLoggedIn
    };
  }
});
