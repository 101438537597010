import { ActionTree } from 'vuex';
import { ClassState } from './types';
import { RootState } from '../types';
import { Api } from '@/api';
import { ClassMetric } from '../../models/ClassMetrics';
import { ErrorResponse, SuccessResponse } from '../../api/types';
import Class from '../../models/Class';
import Course from '../../models/Course';

export function createActions(api: Api): ActionTree<ClassState, RootState> {
  const actions: ActionTree<ClassState, RootState> = {
    async fetchClassMetrics({ commit }): Promise<ClassMetric[]> {
      const classMetrics = await api.fetchClassMetrics();
      commit('SET_CLASS_METRICS', classMetrics);
      return classMetrics;
    },

    async fetchClasses({
      commit
    }): Promise<SuccessResponse<Class[]> | ErrorResponse> {
      const apiResponse = await api.fetchClasses();
      if (apiResponse.constructor === SuccessResponse) {
        commit('SET_ALL_CLASSES', apiResponse.data);
        return apiResponse;
      } else {
        return apiResponse;
      }
    },
    async setCurrentClass(
      { commit },
      classId: string
    ): Promise<SuccessResponse<Class> | ErrorResponse> {
      const apiResponse = await api.fetchClass(classId);
      if (apiResponse.constructor === SuccessResponse) {
        commit('SET_CURRENT_CLASS', apiResponse.data);
        return apiResponse;
      } else {
        return apiResponse;
      }
    },
    async fetchCourses({
      commit
    }): Promise<SuccessResponse<Course[]> | ErrorResponse> {
      const apiResponse = await api.fetchCourses();
      if (apiResponse.constructor === SuccessResponse) {
        commit('SET_ALL_COURSES', apiResponse.data);
        return apiResponse;
      }
      return apiResponse;
    }
  };
  return actions;
}
