import { Api } from '@/api';
import { Module } from 'vuex';
import { RootState } from '../types';
import { createActions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { AdminState } from './types';

export const state: AdminState = {
  publicInfrastructureJobs: []
};

export function create(api: Api): Module<AdminState, RootState> {
  const actions = createActions(api);
  const adminModule: Module<AdminState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return adminModule;
}
