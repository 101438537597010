import {
  Chapter,
  CreateGradedManeuverViewModel,
  Event,
  EventPhases,
  GradedEvent,
  GradedManeuver,
  ManeuverViewModel,
  Student,
  Syllabus,
  Topic
} from '@/models';
import { ClassMetric } from '@/models/ClassMetrics';
import { AxiosInstance } from 'axios';
import firebase from 'firebase/app';
import eventStaticDatabase from '../../cms-generated/eventStaticDatabase.json';
import maneuvers from '../../cms-generated/maneuvers.json';
import Class from '../models/Class';
import Course from '../models/Course';
import {
  InfrastructureBackupIntegrationEvent,
  InfrastructureRestoreIntegrationEvent
} from '../models/InfrastructureIntegrationEvent';
import { UserRole } from '../models/UserRoles';
import FirestoreService, {
  FirestoreServiceDocument,
  FirestoreServiceError,
  FirestoreServiceResult
} from '../services/FirestoreService';
import StorageService from '../services/StorageService';
import classMetrics from './mock/classMetrics.json';
import { getNextEvents } from './Recommendation';
import { StudentApi } from './StudentApi';
import { ErrorResponse, SuccessResponse } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isError(obj: any) {
  if (obj.code) {
    return true;
  }
  return false;
}

export class Api {
  constructor(
    instance: AxiosInstance,
    studentApi: StudentApi,
    firestoreService: FirestoreService,
    storageService: StorageService
  ) {
    this.studentApi = studentApi;
    this.instance = instance;
    this.firestoreService = firestoreService;
    this.storageService = storageService;
  }
  private eventDatabase = eventStaticDatabase as any;
  private firestoreService: FirestoreService;
  private studentApi: StudentApi;
  private instance: AxiosInstance;
  private storageService: StorageService;

  public async fetchManeuvers(): Promise<any[]> {
    // API call TODO
    return maneuvers;
  }

  public async fetchClassMetrics(): Promise<ClassMetric[]> {
    // API call TODO
    return classMetrics.map((json: any) => {
      return ClassMetric.fromJson(json);
    });
  }

  public async fetchPublicInfrastructureJobs(): Promise<
    ErrorResponse | SuccessResponse<FirestoreServiceDocument[]>
  > {
    try {
      const snapshot = await this.firestoreService.fetchPublicInfrastructureJobs();
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        // something bad happened within firestore
        throw Error(
          'FirestoreError - fetchPublicInfrustructureJobs: something really bad happened'
        );
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<
        FirestoreServiceDocument[]
      >;
      return new SuccessResponse(firestoreServiceResult.data);
    } catch (error) {
      // something bad happened somewhere else
      throw Error(`something really bad happened ${error}`);
    }
  }

  public async fetchClasses(): Promise<
    SuccessResponse<Class[]> | ErrorResponse
  > {
    try {
      const snapshot = await this.firestoreService.fetchClasses();
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No classes found');
        }
        // something bad happened within firestore
        throw Error(
          'FirestoreError - fetchClasses: something really bad happened'
        );
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<
        FirestoreServiceDocument[]
      >;
      return new SuccessResponse(
        firestoreServiceResult.data.map((doc) => {
          return Class.fromFirestoreServiceResult(doc);
        })
      );
    } catch (error) {
      // something bad happened somewhere else
      throw Error(`something really bad happened ${error}`);
    }
  }

  public async fetchClass(
    id: string
  ): Promise<SuccessResponse<Class> | ErrorResponse> {
    try {
      const snapshot = await this.firestoreService.fetchClass(id);
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No class found');
        }
        // something bad happened within firestore
        throw Error(
          'FirestoreError - fetchClass: something really bad happened'
        );
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<FirestoreServiceDocument>;
      return new SuccessResponse(
        Class.fromFirestoreServiceResult(firestoreServiceResult.data)
      );
    } catch (error) {
      // something bad happened somewhere else
      throw Error(`something really bad happened ${error}`);
    }
  }

  public async fetchCourses(): Promise<
    SuccessResponse<Course[]> | ErrorResponse
  > {
    function isError(obj: any) {
      if (obj.code) {
        return true;
      }
      return false;
    }
    try {
      const snapshot = await this.firestoreService.fetchCourses();
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No courses found');
        }
        // something bad happened within firestore
        throw Error('something really bad happened');
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<
        FirestoreServiceDocument[]
      >;
      return new SuccessResponse(
        firestoreServiceResult.data.map((doc) => {
          return Course.fromFirestoreServiceResult(doc);
        })
      );
    } catch (error) {
      // something bad happened somewhere else
      throw Error('something really bad happened');
    }
  }

  public async fetchCourse(
    courseId: string
  ): Promise<SuccessResponse<Course> | ErrorResponse> {
    try {
      const snapshot = await this.firestoreService.fetchCourse(courseId);
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No course found');
        }
        // something bad happened within firestore
        throw Error('something really bad happened');
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<FirestoreServiceDocument>;
      return new SuccessResponse(
        Course.fromFirestoreServiceResult(firestoreServiceResult.data)
      );
    } catch (error) {
      // something bad happened somewhere else
      throw Error('something really bad happened');
    }
  }

  public async fetchStudents(): Promise<
    SuccessResponse<Student[]> | ErrorResponse
  > {
    try {
      const snapshot = await this.firestoreService.fetchStudents();
      if (isError(snapshot)) {
        const e = snapshot as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No students found');
        }
        // something bad happened within firestore
        throw Error(
          'FirestoreError - fetchStudents: something really bad happened'
        );
      }
      const firestoreServiceResult = snapshot as FirestoreServiceResult<
        FirestoreServiceDocument[]
      >;
      return new SuccessResponse(
        firestoreServiceResult.data.map((doc) => {
          return Student.fromFirestoreServiceResult(doc);
        })
      );
    } catch (error) {
      // something bad happened somewhere else
      throw Error(`something really bad happened ${error}`);
    }
  }

  public async updateUserRole(uid: string, role: UserRole): Promise<void> {
    this.firestoreService.updateUserRole(uid, role);
  }

  public async fetchUserRole(
    uid: string
  ): Promise<SuccessResponse<UserRole> | ErrorResponse> {
    try {
      const roleQuery = await this.firestoreService.fetchUserRole(uid);

      if (isError(roleQuery)) {
        const e = roleQuery as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        if (e.code == 'not-found') {
          return new ErrorResponse('No user found');
        }
        throw Error(
          'FirestoreError - fetchUserRole: something really bad happened'
        );
      }

      const role = roleQuery as FirestoreServiceResult<UserRole>;
      return new SuccessResponse(role.data);
    } catch (error) {
      // something bad happened somewhere else
      throw Error('fetchUserRole: something really bad happened: \n' + error);
    }
  }

  public async fetchStudent(
    id: string
  ): Promise<SuccessResponse<Student> | ErrorResponse> {
    try {
      const studentDoc = (await this.firestoreService.fetchStudent(
        id
      )) as FirestoreServiceResult<firebase.firestore.DocumentData>;
      if (studentDoc.data.data()) {
        const student = Student.fromFirestoreSnapshot(studentDoc.data);
        return new SuccessResponse(student);
      } else {
        return new ErrorResponse('Failed to load student');
      }
    } catch (error) {
      throw new ErrorResponse('Failed to load student');
    }
  }

  public async fetchStudentGradedEvents(
    id: string
  ): Promise<SuccessResponse<GradedEvent[]> | ErrorResponse> {
    const gradedEventsQuery = (await this.firestoreService.fetchStudentGradedEvents(
      id
    )) as FirestoreServiceResult<firebase.firestore.DocumentData>;

    if (gradedEventsQuery.data.data()) {
      const gradedEventsArray: GradedEvent[] = gradedEventsQuery.data.map(
        (doc: firebase.firestore.DocumentData) => {
          const gradedEvent = doc.data();
          gradedEvent.date = gradedEvent.date.toDate();
          gradedEvent.id = doc.id;
          return GradedEvent.fromJson(gradedEvent);
        }
      );
      return new SuccessResponse(gradedEventsArray);
    } else {
      return new ErrorResponse('Failed to load graded events');
    }
  }

  public async fetchStudentGradedEventsFullyQualified(
    studentId: string,
    filterByPhase: string | null = null
  ): Promise<SuccessResponse<GradedEvent[]> | ErrorResponse> {
    try {
      const gradedEventsQuery = await this.firestoreService.fetchStudentGradedEventsFullyQualified(
        studentId,
        filterByPhase
      );
      if (isError(gradedEventsQuery)) {
        const e = gradedEventsQuery as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - fetchStudentGradedEventsFullyQualified: something really bad happened'
        );
      }

      const gradedEvents = (gradedEventsQuery as FirestoreServiceResult<
        GradedEvent[]
      >).data;
      if (gradedEvents === undefined) {
        return new ErrorResponse('Failed to load graded events');
      } else {
        return new SuccessResponse(gradedEvents);
      }
    } catch (error) {
      throw Error(
        'fetchStudentGradedEventsFullyQualified: something really bad happened: \n' +
          error
      );
    }
  }

  public async createInfrastructureIntegregationEvent(
    integrationEvent:
      | InfrastructureBackupIntegrationEvent
      | InfrastructureRestoreIntegrationEvent
  ): Promise<SuccessResponse<null> | ErrorResponse> {
    try {
      const infrastructureEventReponse = await this.firestoreService.createInfrastructureIntegrationEvent(
        integrationEvent.toJson()
      );
      if (isError(infrastructureEventReponse)) {
        const e = infrastructureEventReponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - createInfrastructureIntegregationEvent: something really bad happened'
        );
      }

      return new SuccessResponse(null);
    } catch (error) {
      throw Error(
        'createInfrastructureIntegregationEvent: something really bad happened: \n' +
          error
      );
    }
  }

  public async createStudentEvent(
    id: string,
    event: GradedEvent
  ): Promise<
    | SuccessResponse<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >
    | ErrorResponse
  > {
    try {
      const studentEventReponse = await this.firestoreService.createStudentGradedEvent(
        id,
        event
      );
      if (isError(studentEventReponse)) {
        const e = studentEventReponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - createStudentGradedEvent: something really bad happened'
        );
      }

      const studentEvent = (studentEventReponse as FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >).data;

      return new SuccessResponse(studentEvent);
    } catch (error) {
      throw Error(
        'createStudentEvent: something really bad happened: \n' + error
      );
    }
  }

  public async setGradedEvent(
    studentId: string,
    event: GradedEvent
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const gradeEventReponse = await this.firestoreService.setGradedEvent(
        studentId,
        event
      );
      if (isError(gradeEventReponse)) {
        const e = gradeEventReponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - setGradedEvent: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error('setGradedEvent: something really bad happened: \n' + error);
    }
  }

  public async setGradedEventManeuver(
    studentId: string,
    eventId: string,
    maneuver: GradedManeuver
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const gradeEventManeuverReponse = await this.firestoreService.setGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      if (isError(gradeEventManeuverReponse)) {
        const e = gradeEventManeuverReponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - setGradedEventManeuver: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'setGradedEventManeuver: something really bad happened: \n' + error
      );
    }
  }

  public async addGradedEventManeuver(
    studentId: string,
    eventId: string,
    maneuver: CreateGradedManeuverViewModel
  ): Promise<
    | SuccessResponse<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >
    | ErrorResponse
  > {
    try {
      const gradeEventManeuverReponse = await this.firestoreService.addGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      if (isError(gradeEventManeuverReponse)) {
        const e = gradeEventManeuverReponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - addGradedEventManeuver: something really bad happened'
        );
      }
      const studentEventResponse = (gradeEventManeuverReponse as FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >).data;

      return new SuccessResponse(studentEventResponse);
    } catch (error) {
      throw Error(
        'addGradedEventManeuver: something really bad happened: \n' + error
      );
    }
  }

  public async fetchStudentEvent(
    studentId: string,
    eventId: string
  ): Promise<SuccessResponse<GradedEvent> | ErrorResponse> {
    try {
      const studentEventResponse = await this.firestoreService.fetchStudentEvent(
        studentId,
        eventId
      );
      if (isError(studentEventResponse)) {
        const e = studentEventResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - fetchStudentEvent: something really bad happened'
        );
      }
      const studentEvent = (studentEventResponse as FirestoreServiceResult<GradedEvent>)
        .data;

      return new SuccessResponse(studentEvent);
    } catch (error) {
      throw Error(
        'fetchStudentEvent: something really bad happened: \n' + error
      );
    }
  }

  public async deleteGradedEvent(
    studentId: string,
    event: GradedEvent
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventResponse = await this.firestoreService.deleteGradedEvent(
        studentId,
        event
      );
      if (isError(studentEventResponse)) {
        const e = studentEventResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - deleteGradedEvent: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'deleteGradedEvent: something really bad happened: \n' + error
      );
    }
  }

  public async deleteGradedEventManeuver(
    studentId: string,
    eventId: string,
    maneuver: ManeuverViewModel
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventManeuverResponse = await this.firestoreService.deleteGradedEventManeuver(
        studentId,
        eventId,
        maneuver
      );
      if (isError(studentEventManeuverResponse)) {
        const e = studentEventManeuverResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - deleteGradedEventManeuver: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'deleteGradedEventManeuver: something really bad happened: \n' + error
      );
    }
  }

  public async addGradedEventManeuverCollection(
    studentId: string,
    maneuvers: CreateGradedManeuverViewModel[]
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventManeuverResponse = await this.firestoreService.addGradedEventManeuverCollection(
        studentId,
        maneuvers
      );
      if (isError(studentEventManeuverResponse)) {
        const e = studentEventManeuverResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - addGradedEventManeuverCollection: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'addGradedEventManeuverCollection: something really bad happened: \n' +
          error
      );
    }
  }

  public async updateEventManeuverCollection(
    studentId: string,
    maneuvers: ManeuverViewModel[]
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventManeuverResponse = await this.firestoreService.setGradedEventManeuverCollection(
        studentId,
        maneuvers
      );
      if (isError(studentEventManeuverResponse)) {
        const e = studentEventManeuverResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - updateEventManeuverCollection: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'updateEventManeuverCollection: something really bad happened: \n' +
          error
      );
    }
  }

  public async deleteGradedEventManeuverCollection(
    studentId: string,
    maneuvers: ManeuverViewModel[]
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventManeuverResponse = await this.firestoreService.deleteGradedEventManeuverCollection(
        studentId,
        maneuvers
      );
      if (isError(studentEventManeuverResponse)) {
        const e = studentEventManeuverResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - updateEventManeuverCollection: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'updateEventManeuverCollection: something really bad happened: \n' +
          error
      );
    }
  }

  public async updateEventCollection(
    studentId: string,
    events: GradedEvent[]
  ): Promise<SuccessResponse<Promise<void>> | ErrorResponse> {
    try {
      const studentEventManeuverResponse = await this.firestoreService.setGradedEventCollection(
        studentId,
        events
      );
      if (isError(studentEventManeuverResponse)) {
        const e = studentEventManeuverResponse as FirestoreServiceError;
        // well known errors
        if (e.code == 'permission-denied') {
          return new ErrorResponse('Permission denied');
        }
        throw Error(
          'FirestoreError - updateEventCollection: something really bad happened'
        );
      }
      return new SuccessResponse(Promise.resolve());
    } catch (error) {
      throw Error(
        'updateEventCollection: something really bad happened: \n' + error
      );
    }
  }

  //Why are all these methods, that arent touching the API, in this class?
  public async fetchSyllabusDownloadURL(
    syllabusFileName: string
  ): Promise<string> {
    const syllabusDownloadURL = await this.storageService.fetchSyllabusDownloadURL(
      syllabusFileName
    );

    return syllabusDownloadURL;
  }

  public async fetchSyllabus(syllabusFileName: string): Promise<Syllabus> {
    const syllabusDownloadURL = await this.fetchSyllabusDownloadURL(
      syllabusFileName
    );

    return Syllabus.fromJson(
      (await this.instance.get(syllabusDownloadURL)).data
    );
  }

  public async fetchSyllabusChapter(
    syllabus: Syllabus,
    name: string
  ): Promise<Chapter | undefined> {
    const chapter = syllabus.chapters.find((chapter) => {
      return chapter.title === name;
    });
    return chapter;
  }

  public async fetchSyllabusTopic(
    syllabus: Syllabus,
    chapterName: string,
    topicName: string
  ): Promise<Topic | undefined> {
    const chapter = await this.fetchSyllabusChapter(syllabus, chapterName);
    if (!chapter) return;
    const topicJson = chapter.topics.find((topic) => {
      return topic.title === topicName;
    });
    return Topic.fromJson(topicJson);
  }

  public async fetchSyllabusEvents(syllabusId: string): Promise<Event[]> {
    return this.eventDatabase[syllabusId].map((jsonEvent: any) => {
      return Event.fromJson(jsonEvent);
    });
  }

  public async fetchSyllabusAcademicEvents(
    syllabusId: string
  ): Promise<Event[]> {
    return this.eventDatabase[syllabusId]
      .map((jsonEvent: any) => {
        return Event.fromJson(jsonEvent);
      })
      .filter((event: Event) => {
        return event.phase === EventPhases.PREFLIGHT;
      });
  }

  public fetchSyllabusEvent(name: string, syllabusId: string): Promise<Event> {
    const jsonEvent = this.eventDatabase[syllabusId].find((jsonEvent: any) => {
      return jsonEvent.name === name;
    });
    const event = Event.fromJson(jsonEvent);
    return Promise.resolve(event);
  }

  public async fetchUnlockedStudentEvents(
    gradedEvents: GradedEvent[],
    syllabusId: string
  ): Promise<Event[]> {
    const unlockedEvents = getNextEvents(gradedEvents, syllabusId);
    return this.eventDatabase[syllabusId]
      .filter((event: any) => {
        return unlockedEvents.has(event.name);
      })
      .map((json: any) => {
        return Event.fromJson(json);
      });
  }
}
