enum Icon {
  NONE,
  BULLSEYE,
  BOOK_INFO,
  CALENDAR,
  CHART,
  CHECK,
  CHEVRON_DOWN,
  CHEVRON_RIGHT,
  CHEVRON_UP_LIGHT,
  DOUBLE_CHEVRON_DOWN,
  DOUBLE_CHEVRON_UP,
  DOUBLE_CHEVRON_LEFT,
  DOUBLE_CHEVRON_RIGHT,
  DOCUMENT,
  FUNCTION,
  GRADE_E,
  GRADE_F,
  GRADE_G,
  GRADE_NG,
  GRADE_U,
  PLAN,
  PEOPLE,
  PEOPLE_STAR,
  COHORTS,
  LOADING,
  LOGO,
  RED_CROSS,
  SEARCH,
  TABLE,
  TREND_UP,
  TREND_DOWN,
  TREND_NONE,
  RECOMMENDATION,
  WINGS,
  RED_CRITICAL,
  BLUE_CHECK,
  HALF_RED_CHECK,
  UNKNOWN,
  EMPTY,
  CARDINALITY
}

export default Icon;
