import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative flex items-center bg-primary-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_icon_component, {
      icon: _ctx.Icon.SEARCH,
      class: "absolute ml-2 text-white"
    }, null, 8, ["icon"]),
    _withDirectives(_createVNode("input", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchText = $event)),
      class: "p-2 py-1 pl-8 text-sm border rounded focus:outline-none bg-primary-dark"
    }, null, 512), [
      [_vModelText, _ctx.searchText]
    ])
  ]))
}