/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

const RETRY_STATUS_CODES = [408, 429, 502, 503, 504];
interface IApi {
  get(url: string): Promise<any>;
  delete(url: string): Promise<any>;
  patch(url: string, payload: any): Promise<any>;
  post(url: string, payload: any): Promise<any>;
  put(url: string, payload: any): Promise<any>;
}

interface ApiConfig {
  params?: AxiosRequestConfig;
  maxTries?: number;
  retryDelay?: number;
}

class Api implements IApi {
  protected instance: AxiosInstance;

  public constructor(axiosInstance: AxiosInstance) {
    this.instance = axiosInstance;
  }

  public async attemptRequest(
    // eslint-disable-next-line @typescript-eslint/ban-types
    request: Function,
    url: string,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    payload?: any,
    params?: AxiosRequestConfig,
    maxRetry = 3,
    delayMs = 1000
  ): Promise<any> {
    while (maxRetry > 0) {
      try {
        if (request == this.instance.get || request == this.instance.delete) {
          const { data, headers } = await request(url, params);
          return { data, headers };
        } else {
          const { data, headers } = await request(url, payload, params);
          return { data, headers };
        }
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (
          axiosErr.isAxiosError &&
          ((axiosErr.response &&
            RETRY_STATUS_CODES.indexOf(axiosErr.response.status) !== -1) ||
            axiosErr.message === 'Network Error' ||
            axiosErr.message.includes('timeout'))
        ) {
          await this.timeout(delayMs);
          delayMs *= 2;
          --maxRetry;
        } else {
          throw err;
        }
      }
    }
    throw Error('exhausted number of retries');
  }

  private async timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async get(url = '', config?: ApiConfig): Promise<any> {
    return this.attemptRequest(
      this.instance.get,
      url,
      undefined,
      config?.params,
      config?.maxTries,
      config?.retryDelay
    );
  }

  public async delete(url = '', config?: ApiConfig): Promise<any> {
    return this.attemptRequest(
      this.instance.delete,
      url,
      undefined,
      config?.params,
      config?.maxTries,
      config?.retryDelay
    );
  }

  public async post(
    url = '',
    payload: any = {},
    config?: ApiConfig
  ): Promise<any> {
    return this.attemptRequest(
      this.instance.post,
      url,
      payload,
      config?.params,
      config?.maxTries,
      config?.retryDelay
    );
  }

  public async put(
    url = '',
    payload: any = {},
    config?: ApiConfig
  ): Promise<any> {
    return this.attemptRequest(
      this.instance.put,
      url,
      payload,
      config?.params,
      config?.maxTries,
      config?.retryDelay
    );
  }

  public async patch(
    url = '',
    payload: any = {},
    config?: ApiConfig
  ): Promise<any> {
    return this.attemptRequest(
      this.instance.patch,
      url,
      payload,
      config?.params,
      config?.maxTries,
      config?.retryDelay
    );
  }

  public async uploadToGoogle(
    progressHandler: (progressEvent: ProgressEvent) => void,
    url: string,
    payload: File
  ): Promise<AxiosResponse<any>> {
    const args: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressHandler
    };
    return axios.put(url, payload, args);
  }

  public async uploadToPlatformOne(
    progressHandler: (progressEvent: ProgressEvent) => void,
    url: string,
    payload: File
  ): Promise<AxiosResponse<any>> {
    const data = new FormData();
    data.append('file', payload);
    const args: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressHandler
    };
    return axios.put(url, data, args);
  }
}

export { Api, IApi };
