import { MutationTree } from 'vuex';
import { EventState } from './types';
import { GradedEvent, GradedManeuver } from '@/models';

export const mutations: MutationTree<EventState> = {
  SET_SYLLABUS_EVENTS(state, syllabusEvents: Event[]) {
    state.syllabusEvents = syllabusEvents;
  },
  SET_SYLLABUS_EVENT(state, syllabusEvent: Event) {
    state.syllabusEvent = syllabusEvent;
  },
  SET_UNLOCKED_EVENTS(state, events: GradedEvent[]) {
    state.unlockedEvents = events;
  },
  ADD_SCHEDULED_EVENT(state, event: GradedEvent) {
    state.scheduledEvents.push(event);
  },
  REMOVE_SCHEDULED_EVENT(state, event: GradedEvent) {
    const index = state.scheduledEvents.findIndex((e) => e.name === event.name);
    if (index !== -1) {
      state.scheduledEvents.splice(index, 1);
    }
  },
  SET_MANEUVERS(state, maneuvers: GradedManeuver[]) {
    state.maneuvers = maneuvers;
  },
  SET_SYLLABUS_ACADEMIC_EVENTS(state, syllabusAcademicEvents: Event[]) {
    state.syllabusAcademicEvents = syllabusAcademicEvents;
  }
};
