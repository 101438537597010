import { MutationTree } from 'vuex';
import { UserState } from './types';
import { IUser } from '@/models/User';
import firebase from 'firebase';

export const mutations: MutationTree<UserState> = {
  SET_USER(state, user: IUser): void {
    state.currentUser = user;
  },
  SET_FIREBASE_USER(state, user: firebase.User) {
    state.firebaseUser = user;
  },
  SET_IS_LOGGED_IN(state, isLoggedIn: boolean): void {
    state.isLoggedIn = isLoggedIn;
  },
  SET_IS_EMAIL_VERIFIED(state, isEmailVerified: boolean): void {
    state.isEmailVerified = isEmailVerified;
  }
};
