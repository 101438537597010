import { plainToClass } from 'class-transformer';
import { FirestoreServiceDocument } from '../services/FirestoreService';

export default class Course {
  constructor() {
    this.id = '';
    this.syllabusOptions = [];
  }
  id!: string;
  syllabusOptions!: {
    label: string;
    downloadId: string;
  }[];

  // eslint-disable-next-line
  static fromJson(payload?: any): Course {
    return plainToClass(Course, payload);
  }
  static fromFirestoreServiceResult(
    snapshot: FirestoreServiceDocument
  ): Course {
    const classData = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    return classData;
  }
}
