import { GradedEvent, gradeItems, Recommendation } from '../models';
import prerequisitesGraph from '../../cms-generated/prerequisitesGraph.json';
import detailedRecommendationMap from '@/api/detailedRecommendationMap.json';
import SyllabusIdsType from '../../cms-generated/SyllabusIdsType';

function eventIsBelowMif(event: GradedEvent) {
  return event.overallGrade === '2';
}

function eventIsGraded(event: GradedEvent) {
  return !!event.overallGrade && event.overallGrade !== '';
}

function getDetailedRecommendations(events: GradedEvent[]): Recommendation[] {
  const belowMifEvents = events.filter((event) => {
    return eventIsBelowMif(event) && eventIsGraded(event);
  });

  const flattenedManeuverList = belowMifEvents
    .map((event) => event.maneuvers)
    .flat()
    .filter(
      (maneuver) =>
        maneuver.grade !== '' &&
        maneuver.reason.key &&
        maneuver.reason.key !== ''
    );

  const maneuverCountMap = flattenedManeuverList.reduce((acc, maneuver) => {
    if (!acc[maneuver.reason.key]) {
      acc[maneuver.reason.key] = { count: 1, maneuver };
    } else {
      acc[maneuver.reason.key].count++;
    }
    return acc;
  }, {} as any);

  const recommendationsMap: {
    [key: string]: any;
  } = detailedRecommendationMap;

  const recommendations: Recommendation[] = Object.keys(maneuverCountMap).map(
    (key) => {
      const maneuverEntry = maneuverCountMap[key];
      const corrections = recommendationsMap[maneuverEntry.maneuver.name];
      return Recommendation.fromJson({
        maneuver: maneuverEntry.maneuver,
        corrections
      });
    }
  );
  return recommendations;
}

function eventIsCompleted(event: GradedEvent) {
  const gradeItem = gradeItems.find(
    (gradeItem) => gradeItem.grade === event.overallGrade
  );
  return gradeItem && gradeItem.isPassingGrade;
}

function getNextEvents(events: GradedEvent[], syllabusId: string): Set<string> {
  // get completed events
  const completedEventsById: {
    [key: string]: GradedEvent;
  } = {};
  const completedEvents = events.filter((event) => {
    return eventIsCompleted(event);
  });
  completedEvents.forEach((completedEvent) => {
    completedEventsById[completedEvent.name] = completedEvent;
  });

  // find all events that have their prerequisites met
  interface node {
    prerequisites: string[];
  }
  interface syllabus {
    [key: string]: node;
  }
  const graph: {
    [key: string]: syllabus;
  } = prerequisitesGraph;

  const unlockedEvents = Object.keys(
    prerequisitesGraph[syllabusId as SyllabusIdsType]
  )
    .map((key: string) => {
      const prerequisites = graph[syllabusId][key].prerequisites;
      const met = prerequisites.map((prerequisite) => {
        return !!completedEventsById[prerequisite];
      });
      const doesMeetAll =
        met.filter((met) => met === false).length === 0 || key === 'Q4T01';
      if (doesMeetAll) {
        return key;
      }
      return '';
    })
    .filter((item) => item !== '');

  return new Set(unlockedEvents);
}

export { getDetailedRecommendations, getNextEvents };
