import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.src)
    ? (_openBlock(), _createBlock("img", {
        key: 0,
        src: _ctx.src,
        alt: _ctx.src
      }, null, 8, ["src", "alt"]))
    : _createCommentVNode("", true)
}