import { User } from '@/models/User';
import { RootState } from '@/store/types';
import firebase from 'firebase/app';
import 'firebase/auth';
import { NavigationGuardNext } from 'vue-router';
import { Store } from 'vuex';

class AuthRouteHandler {
  private static store: Store<RootState>;

  static initialize(store: Store<RootState>, router: any): void {
    this.store = store;
    firebase.auth().onAuthStateChanged(async (user) => {
      await AuthRouteHandler.onStateChange(router, user);
    });
    router.beforeEach((to: any, from: any, next: any) => {
      AuthRouteHandler.onRouterEntry(to, next);
    });
  }

  /*
    Auth state changed handler. This gets triggered when there is a change to the currently logged in firebase user
    Login, logout, signup etc.
  */
  static async onStateChange(router: any, user: firebase.User | null) {
    try {
      // Logout
      if (!user) {
        await this.store.dispatch('userModule/logout');
        return;
      } else {
        await this.store.dispatch('userModule/login', user);
      }
      // Set all authorization headers for API
      this.store.commit(`userModule/SET_FIREBASE_USER`, user);

      // Redirect user if on the login/signup page and there is a redirect query param
      const currentRoute = router.currentRoute.value;
      const redirect = currentRoute.query.redirect
        ? String(currentRoute.query.redirect)
        : null;
      if (
        (currentRoute.name === 'login' || currentRoute.name === 'signup') &&
        redirect
      ) {
        delete currentRoute.query.redirect; // Don't need this hanging around
        const newRoute = {
          path: redirect,
          query: currentRoute.query
        };
        router.push(newRoute);
      }
    } catch (err) {
      // /* tslint:disable:no-console */
      // console.log(err);
    }
  }

  /*
    Router middleware. Guard each route depending on the route configuration in router.ts.
    Ensure the currently signed in user has the correct role to see the route.
  */
  static onRouterEntry(to: any, next: NavigationGuardNext): void {
    try {
      const currentUser = this.store.getters['userModule/currentUser'];
      const isLoggedIn = this.store.getters['userModule/isLoggedIn'];
      const isEmailVerified = this.store.getters['userModule/isEmailVerified'];
      // if (config.firebase.auth.local) {
      //   isEmailVerified = true;
      // }
      const requiresRoles =
        to.meta && to.meta.roles && to.meta.roles.length > 0 ? true : false;
      const requiresAuth = to.matched.some(
        (record: any) => record.meta.requiresAuth
      );
      if (
        isLoggedIn &&
        !isEmailVerified &&
        to.name !== 'verification' &&
        to.name !== 'login'
      ) {
        next({
          name: 'verification'
        });
      } else if (requiresAuth && !isLoggedIn) {
        next({
          name: 'login',
          query: { redirect: to.path, ...to.query }
        });
      } else if (
        requiresRoles &&
        !this.hasValidRole(currentUser, to.meta.roles)
      ) {
        next({
          name: 'home'
        });
      } else {
        next();
      }
    } catch (err) {
      /* tslint:disable:no-console */
      // console.log(err);
    }
  }

  static hasValidRole(
    currentUser: User | null,
    requiredRoles: number[]
  ): boolean {
    if (!requiredRoles || !requiredRoles.length) {
      return true;
    }
    if (!currentUser || currentUser.role == null) {
      return false;
    }
    if (requiredRoles.indexOf(currentUser.role) > -1) {
      return true;
    }
    return false;
  }
}

export default AuthRouteHandler;
