
import { defineComponent, PropType } from 'vue';
import SearchComponent from '@/components/SearchComponent.vue';
import LoginButtonComponent from '@/components/LoginButtonComponent.vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import Icon from '@/components/icon/Icon';
import { LocationQuery } from 'vue-router';
import Routes from '@/router/Routes';
import { User } from '@/models/User';

export type NavigationLink = {
  to: LocationQuery | string;
  label: string;
  icon: Icon;
};

export default defineComponent({
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    isEmailVerified: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object as PropType<User>
    }
  },
  components: {
    LoginButtonComponent,
    IconComponent,
    SearchComponent
  },
  setup() {
    const navigationLinks: NavigationLink[] = [
      // {
      //   to: '/',
      //   label: 'Home',
      //   icon: Icon.CHART
      // },
      // {
      //   to: {
      //     name: Routes.PLAN
      //   },
      //   label: 'Plan',
      //   icon: Icon.PLAN
      // },
      // {
      //   to: '/evaluate',
      //   label: 'Evaluate',
      //   icon: Icon.BULLSEYE
      // },
      {
        to: {
          name: Routes.STUDENT_DASHBOARD
        },
        label: 'Students',
        icon: Icon.PEOPLE
      },
      {
        to: {
          name: Routes.SYLLABUS
        },
        label: 'Syllabus',
        icon: Icon.COHORTS
      }
    ];
    return {
      navigationLinks,
      Icon
    };
  }
});
