
import { computed, defineComponent, PropType } from 'vue';
import Icon from './icon/Icon';
import IconComponent from './icon/IconComponent.vue';

export default defineComponent({
  components: { IconComponent },
  props: {
    icon: {
      type: Number as PropType<Icon>
    },
    small: {
      type: Boolean
    },
    bgClass: {
      type: String,
      default: 'bg-primary'
    },
    textClass: {
      type: String,
      default: 'text-white'
    },
    borderClass: {
      type: String,
      default: 'border-0'
    }
  },
  setup(props) {
    const classes = computed(() => {
      let classString = `${props.bgClass} ${props.textClass} ${props.borderClass}`;
      if (props.small) {
        classString += ' small';
      }
      return classString;
    });
    return {
      classes
    };
  }
});
