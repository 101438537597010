import { plainToClass, Type } from 'class-transformer';
import { GradedEvent } from './Event';
import { GradedManeuver } from './Maneuver';

export class Recommendation {
  @Type(() => Number)
  id?: number;
  @Type(() => GradedEvent)
  event!: GradedEvent;
  maneuver!: GradedManeuver;
  reasons!: string[];
  recommendations!: string[];
  corrections?: string[];

  // eslint-disable-next-line
  static fromJson(payload?: any): Recommendation {
    return plainToClass(Recommendation, payload);
  }
}
