export type Errors =
  | 'No students found'
  | 'No classes found'
  | 'No courses found'
  | 'No course found'
  | 'No class found'
  | 'Failed to load students'
  | 'Failed to load student'
  | 'Failed to load graded events'
  | 'Failed to load classes'
  | 'No user found'
  | 'Permission denied';

export class SuccessResponse<T> {
  private resource: T;
  constructor(resource: T) {
    this.resource = resource;
  }
  public get data(): T {
    return this.resource;
  }
}
export class ErrorResponse {
  private _error: Errors;
  constructor(error: Errors) {
    this._error = error;
  }
  public get error(): Errors {
    return this._error;
  }
}

export class LoadingResponse {
  private _isLoading: boolean;
  constructor() {
    this._isLoading = true;
  }
  public get isLoading(): boolean {
    return this._isLoading;
  }
}

export type ResponseTypes<T> =
  | ErrorResponse
  | SuccessResponse<T>
  | LoadingResponse;
