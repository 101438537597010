const Routes = {
  ADMIN: 'admin',
  LOGIN: 'login',
  PLAN: 'plan',
  PLAN_EVENT_COLLECTION: 'plan.event.collection',
  PLAN_STUDENT_DETAILS: 'plan.student.details',
  PROFILE: 'profile',
  STUDENT: 'student',
  STUDENT_DASHBOARD: 'student.dashboard',
  STUDENT_DETAILS: 'student.details',
  STUDENT_EVENTS: 'student.events',
  STUDENT_SKILL_GRAPH: 'student.skillgraph',
  STUDENT_EVENT_DETAILS: 'student.event.details',
  STUDENT_SIM_EVENTS: 'student.simulation.events',
  STUDENT_ACADEMIC_EVENTS: 'student.academic.events',
  STUDENT_PROGRESS: 'student.progress',
  SYLLABUS: 'syllabus',
  SYLLABUS_EVENT_DETAILS: 'syllabus.event.details',
  SYLLABUS_TOPIC_EVENT_DETAILS: 'syllabus.topic.event.details',
  SYLLABUS_CHAPTER_DETAILS: 'syllabus.chapter.details',
  SYLLABUS_TOPIC_DETAILS: 'syllabus.topic.details',
  STUDENT_PROJECTIONS: 'student.projections'
};

export default Routes;
