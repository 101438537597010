import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { class: "app-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation_component = _resolveComponent("navigation-component")
  const _component_error_alert = _resolveComponent("error-alert")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_navigation_component, {
      isLoggedIn: _ctx.isLoggedIn,
      isEmailVerified: _ctx.isEmailVerified,
      currentUser: _ctx.currentUser,
      class: "app-navigation"
    }, null, 8, ["isLoggedIn", "isEmailVerified", "currentUser"]),
    _createVNode("div", _hoisted_2, [
      (_ctx.error)
        ? (_openBlock(), _createBlock(_component_error_alert, {
            key: 0,
            message: _ctx.error,
            "data-testid": "error-alert"
          }, null, 8, ["message"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ])
  ]))
}