import { Api } from '@/api';
import { ActionTree, Commit } from 'vuex';
import { SuccessResponse, ErrorResponse } from '../../api/types';
import {
  InfrastructureBackupIntegrationEvent,
  InfrastructureRestoreIntegrationEvent
} from '../../models/InfrastructureIntegrationEvent';
import { MutationTypes } from '../admin/mutations';
import { RootState } from '../types';
import { AdminState } from './types';

function typedCommit(
  commit: Commit,
  mutationType: MutationTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) {
  return commit(mutationType, payload);
}

export function createActions(api: Api): ActionTree<AdminState, RootState> {
  const actions: ActionTree<AdminState, RootState> = {
    async getPublicInfrustructureJobs({ commit }) {
      const apiResponse = await api.fetchPublicInfrastructureJobs();
      if (apiResponse.constructor === SuccessResponse) {
        const data = apiResponse.data.map((job) => {
          return job.data;
        });
        typedCommit(commit, 'SET_PUBLIC_INFRUSTRUCTURE_JOBS', data);
      } else if (apiResponse.constructor === ErrorResponse) {
        throw new Error(apiResponse.error);
      }
    },
    async createBackupRequest(_, event: InfrastructureBackupIntegrationEvent) {
      const apiResponse = await api.createInfrastructureIntegregationEvent(
        event
      );
      if (apiResponse.constructor === SuccessResponse) {
        return null;
      } else if (apiResponse.constructor === ErrorResponse) {
        throw new Error(apiResponse.error);
      }
    },
    async createRestoreRequest(
      _,
      event: InfrastructureRestoreIntegrationEvent
    ) {
      const apiResponse = await api.createInfrastructureIntegregationEvent(
        event
      );
      if (apiResponse.constructor === SuccessResponse) {
        return null;
      } else if (apiResponse.constructor === ErrorResponse) {
        throw new Error(apiResponse.error);
      }
    }
  };

  return actions;
}
