
import { defineComponent, PropType } from 'vue';
import ButtonComponent from './ButtonComponent.vue';
import ProfilePhotoComponent from './profile/ProfilePhotoComponent.vue';
import Routes from '@/router/Routes';
import { User } from '../models/User';

export default defineComponent({
  components: { ButtonComponent, ProfilePhotoComponent },
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    isEmailVerified: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object as PropType<User>
    }
  },
  setup() {
    return {
      Routes
    };
  }
});
