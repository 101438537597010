import { RouteRecordRaw } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from '@/router/Routes';

export const generateRoutes: (store: Store<RootState>) => RouteRecordRaw[] = (
  store
) => [
  {
    path: '/plan',
    name: Routes.PLAN,
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Plan/PlanPage.vue'),
    async beforeEnter(to, from, next) {
      await store.dispatch('studentModule/fetchAll');
      next();
    },
    props: () => {
      return {
        students: store.getters['studentModule/students']
      };
    },
    children: [
      {
        path: '',
        name: Routes.PLAN_EVENT_COLLECTION,
        component: () => import('@/views/Plan/PlanEventCollectionPage.vue'),
        props: () => {
          return {
            scheduledEvents: store.getters['eventModule/scheduledEvents']
          };
        }
      },
      {
        path: 'student/:id',
        name: Routes.PLAN_STUDENT_DETAILS,
        component: () => import('@/views/Plan/PlanStudentDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          await store.dispatch('studentModule/fetch', to.params.id);
          await store.dispatch(
            'studentModule/fetchRecommendations',
            Number(to.params.id)
          );
          await store.dispatch(
            'studentModule/fetchStudentGradedEventsFullyQualified',
            { studentId: Number(to.params.id) }
          );
          next();
        },
        props: () => {
          return {
            student: store.getters['studentModule/student'],
            events: store.getters['studentModule/studentEvents'],
            recommendations:
              store.getters['studentModule/studentRecommendations']
          };
        }
      }
    ]
  }
];
