import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from './Routes';
import { UserRole } from '../models/UserRoles';
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router
} from 'vue-router';

export class RoleRouteHandler {
  static initialize(store: Store<RootState>, router: Router): void {
    router.beforeEach((to, from, next) => {
      switch (to.name) {
        case Routes.STUDENT_DASHBOARD:
          handleStudentPageRouter(to, store, next);
          break;
        case Routes.SYLLABUS:
          handleSyllabusPageRouter(to, store, next);
          break;
        case Routes.ADMIN:
          handleAdminPageRouter(to, store, next);
          break;
        default:
          next();
          break;
      }
    });
    function handleAdminPageRouter(
      to: RouteLocationNormalized,
      store: Store<RootState>,
      next: NavigationGuardNext
    ) {
      if (store.getters['userModule/currentUser'].role === UserRole.ADMIN) {
        next();
      } else {
        //todo should be a 401
        next({ name: 'home' });
      }
    }
    function handleStudentPageRouter(
      to: RouteLocationNormalized,
      store: Store<RootState>,
      next: NavigationGuardNext
    ) {
      if (store.getters['userModule/currentUser'].role === UserRole.STUDENT) {
        next({
          name: Routes.STUDENT_PROGRESS,
          params: {
            id: store.getters['userModule/currentUser'].uid
          }
        });
      } else {
        next();
      }
    }
    async function handleSyllabusPageRouter(
      to: RouteLocationNormalized,
      store: Store<RootState>,
      next: NavigationGuardNext
    ) {
      const user = store.getters['userModule/currentUser'];
      if (user.role === UserRole.STUDENT) {
        await store.dispatch('studentModule/fetch', user.uid);
        const student = store.getters['studentModule/student'];
        await store.dispatch('classModule/setCurrentClass', student.classId);
        const classData = store.getters['classModule/currentClass'];
        await store.dispatch(
          'syllabusModule/fetchSyllabus',
          classData.syllabusUrl
        );
        await store.dispatch('classModule/fetchClasses');
        next();
      } else if (user.role === UserRole.INSTRUCTOR) {
        await store.dispatch('classModule/fetchCourses');
        const courses = await store.getters['classModule/allCourses'];

        if (
          courses.length >= 1 &&
          courses[0].syllabusOptions[0] &&
          courses[0].syllabusOptions[0].downloadId
        ) {
          await store.dispatch(
            'syllabusModule/fetchSyllabus',
            courses[0].syllabusOptions[0].downloadId
          );
        }
        next();
      }
    }
  }
}
