import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { EventState } from './types';
import { Api } from '@/api';

export const state: EventState = {
  unlockedEvents: [],
  scheduledEvents: [],
  maneuvers: [],
  syllabusEvents: [],
  syllabusEvent: {} as Event,
  syllabusAcademicEvents: []
};

export function create(api: Api): Module<EventState, RootState> {
  const actions = createActions(api);
  const applicantModule: Module<EventState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return applicantModule;
}
