import { GetterTree } from 'vuex';
import { ClassState } from './types';
import { RootState } from '../types';
import { ClassMetric } from '../../models/ClassMetrics';
import Class from '../../models/Class';
import Course from '../../models/Course';

export const getters: GetterTree<ClassState, RootState> = {
  classMetrics(state): ClassMetric[] {
    return state.classMetrics;
  },
  allClasses(state): Class[] {
    return state.allClasses;
  },
  allCourses(state): Course[] {
    return state.allCourses;
  },
  currentClass(state): Class {
    return state.currentClass;
  }
};
