import Icon from '@/components/icon/Icon';
import { GradeIconMap } from '.';

export type GradeItem = {
  grade: null | 'NONE' | '1' | '2' | '3' | '4' | '5';
  key: '' | 'n' | 'u' | 'f' | 'g' | 'e';
  text: '' | 'NG' | 'U' | 'F' | 'G' | 'E';
  icon?: Icon;
  isPassingGrade: true | false;
};

export const gradeItems: GradeItem[] = [
  {
    grade: '1',
    key: 'n',
    text: 'NG',
    icon: GradeIconMap.get('1'),
    isPassingGrade: false
  },
  {
    grade: '2',
    key: 'u',
    text: 'U',
    icon: GradeIconMap.get('2'),
    isPassingGrade: true
  },
  {
    grade: '3',
    key: 'f',
    text: 'F',
    icon: GradeIconMap.get('3'),
    isPassingGrade: true
  },
  {
    grade: '4',
    key: 'g',
    text: 'G',
    icon: GradeIconMap.get('4'),
    isPassingGrade: true
  },
  {
    grade: '5',
    key: 'e',
    text: 'E',
    icon: GradeIconMap.get('5'),
    isPassingGrade: true
  },
  {
    grade: null,
    key: '',
    text: '',
    icon: Icon.RED_CROSS,
    isPassingGrade: false
  }
];
