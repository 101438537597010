import { MutationTree } from 'vuex';
import { CourseState } from './types';

export const mutations: MutationTree<CourseState> = {
  SET_CURRENT_COURSE(state, course) {
    state.currentCourse = course;
  },
  SET_ALL_COURSES(state, courses) {
    state.allCourses = courses;
  }
};
