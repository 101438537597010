
import { defineComponent, PropType } from 'vue';
import { User } from '../../models/User';

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>
    }
  }
});
