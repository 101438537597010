import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { StudentState } from './types';
import { GradedEvent, Recommendation, Student } from '@/models';
import { Api } from '@/api';
import { ResponseTypes } from '../../api/types';

export const state: StudentState = {
  students: [],
  student: {} as Student,
  studentEvents: [],
  studentEvent: {} as GradedEvent,
  studentEventsQuery: { filterByPhase: null },
  studentRecommendations: [],
  studentRequest: {} as ResponseTypes<Student>,
  studentsRequest: {} as ResponseTypes<Student[]>,
  studentEventsRequest: {} as ResponseTypes<GradedEvent[]>,
  studentRecommendationsRequest: {} as ResponseTypes<Recommendation[]>,
  isSavingEvent: false,
  isSavingManeuver: false
};

export function create(api: Api): Module<StudentState, RootState> {
  const actions = createActions(api);
  const applicantModule: Module<StudentState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return applicantModule;
}
