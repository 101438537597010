import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw
} from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Home from '../views/HomePage.vue';
// routes
import { generateRoutes as generatePlanRoutes } from './plan';
import { generateRoutes as generateStudentRoutes } from './student';
import { generateRoutes as generateSyllabusRoutes } from './syllabus';
import { generateRoutes as generateAdminRoutes } from './admin';
import Routes from './Routes';

export function createSkylineRouter(store: Store<RootState>): Router {
  const routes: Array<RouteRecordRaw> = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: () =>
        import(/* webpackChunkName: "signup" */ '../views/SignUpPage.vue')
    },
    {
      path: '/verification',
      name: 'verification',
      component: () =>
        import(
          /* webpackChunkName: "verification" */ '../views/VerificationPage.vue'
        )
    },
    {
      path: '/profile',
      name: Routes.PROFILE,
      component: () =>
        import(/* webpackChunkName: "profile" */ '../views/ProfilePage.vue')
    },
    ...generateAdminRoutes(store),
    ...generatePlanRoutes(store),
    ...generateStudentRoutes(store),
    ...generateSyllabusRoutes(store),
    {
      path: '/cohorts',
      name: 'cohorts',
      component: () =>
        import(/* webpackChunkName: "cohorts" */ '../views/CohortsPage.vue')
    },
    {
      path: '/evaluate',
      name: 'evaluate',
      component: () =>
        import(/* webpackChunkName: "evaluate" */ '../views/EvaluatePage.vue')
    }
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  return router;
}

// export default router;
