import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "rounded-full border overflow-hidden bg-background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.user && _ctx.user.photoUrl !== '')
      ? (_openBlock(), _createBlock("img", {
          key: 0,
          src: _ctx.user.photoUrl,
          class: "w-full h-full"
        }, null, 8, ["src"]))
      : (_openBlock(), _createBlock(_component_fa, {
          key: 1,
          class: "w-full h-full",
          icon: "user",
          type: "fas"
        }))
  ]))
}