import { classToPlain } from 'class-transformer';
import firebase from 'firebase/app';
import { buildConfig } from '../config';
import {
  CreateGradedManeuver,
  CreateGradedManeuverViewModel,
  GradedEvent,
  GradedManeuver,
  ManeuverViewModel
} from '../models';
import Reason from '../models/Reason';
import { UserRole } from '../models/UserRoles';

const config = buildConfig(process.env);

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

export const firestoreDatabase = firebase.firestore();

if (config.firebase.firestore.local) {
  firestoreDatabase.useEmulator(
    config.firebase.firestore.host,
    config.firebase.firestore.port
  );

  if (config.isEndToEnd) {
    firestoreDatabase.settings({
      experimentalForceLongPolling: true,
      merge: true
    });
  }
}

let instance: FirestoreService;

export type FirestoreErrorCode =
  | 'cancelled'
  | 'invalid-argument'
  | 'deadline-exceeded'
  | 'not-found'
  | 'already-exists'
  | 'permission-denied'
  | 'resource-exhausted'
  | 'failed-precondition'
  | 'aborted'
  | 'out-of-range'
  | 'unimplemented'
  | 'internal'
  | 'unavailable'
  | 'data-loss'
  | 'unauthenticated';

export interface FirestoreServiceError {
  code: FirestoreErrorCode;
  message: string;
  name: string;
  stack?: string;
}

export interface FirestoreServiceDocument {
  data: {
    // needs to be refactored
    [field: string]: any;
  };
  id: string;
}

export class FirestoreServiceResult<T> {
  constructor(data: T) {
    this.data = data;
  }
  data: T;
}

export default class FirestoreService {
  database: firebase.firestore.Firestore;
  constructor() {
    this.database = firestoreDatabase;
  }

  static getInstance(): FirestoreService {
    if (!instance) {
      instance = new FirestoreService();
    }
    return instance;
  }

  async fetchPublicInfrastructureJobs(): Promise<
    FirestoreServiceError | FirestoreServiceResult<FirestoreServiceDocument[]>
  > {
    try {
      const query = await this.database
        .collection('infrastructure')
        .doc('public_job_queue')
        .collection('entries')
        .limit(20)
        .get();
      return new FirestoreServiceResult<FirestoreServiceDocument[]>(
        query.docs.map((doc) => {
          return {
            data: doc.data(),
            id: doc.id
          };
        })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchClasses(): Promise<
    FirestoreServiceResult<FirestoreServiceDocument[]> | FirestoreServiceError
  > {
    try {
      const query = await this.database.collection('class').get();
      return new FirestoreServiceResult<FirestoreServiceDocument[]>(
        query.docs.map((doc) => {
          return {
            data: doc.data(),
            id: doc.id
          };
        })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchClass(
    id: string
  ): Promise<
    FirestoreServiceResult<FirestoreServiceDocument> | FirestoreServiceError
  > {
    try {
      const query = await this.database.collection('class').doc(id).get();
      if (query.exists) {
        return new FirestoreServiceResult<FirestoreServiceDocument>({
          data: query.data() as { [field: string]: any },
          id: query.id
        });
      } else {
        return {
          code: 'not-found',
          message: 'Class Not Found',
          name: 'Not Found Error'
        } as FirestoreServiceError;
      }
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchCourses(): Promise<
    FirestoreServiceResult<FirestoreServiceDocument[]> | FirestoreServiceError
  > {
    try {
      const query = await this.database.collection('course').get();
      return new FirestoreServiceResult<FirestoreServiceDocument[]>(
        query.docs.map((doc) => {
          return {
            data: doc.data(),
            id: doc.id
          };
        })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchCourse(
    id: string
  ): Promise<
    FirestoreServiceResult<FirestoreServiceDocument> | FirestoreServiceError
  > {
    try {
      const query = await this.database.collection('course').doc(id).get();
      if (query.exists) {
        return new FirestoreServiceResult<FirestoreServiceDocument>({
          data: query.data() as { [field: string]: any },
          id: query.id
        });
      } else {
        return {
          code: 'not-found',
          message: 'Not Found',
          name: 'Not Found Error'
        } as FirestoreServiceError;
      }
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchStudents(): Promise<
    FirestoreServiceResult<FirestoreServiceDocument[]> | FirestoreServiceError
  > {
    try {
      const query = this.database.collection('student');
      const test = await query.get();
      const serviceDocuments = test.docs.map((doc) => {
        const firestoreServiceDocument: FirestoreServiceDocument = {
          data: doc.data(),
          id: doc.id
        };
        return firestoreServiceDocument;
      });
      return new FirestoreServiceResult<FirestoreServiceDocument[]>(
        serviceDocuments
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async updateUserRole(
    userId: string,
    role: UserRole
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      return new FirestoreServiceResult<void>(
        await this.database.collection('user').doc(userId).set({
          role
        })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchUserRole(
    userId: string
  ): Promise<FirestoreServiceResult<UserRole> | FirestoreServiceError> {
    try {
      let adminRoleQuery;
      try {
        adminRoleQuery = await this.database
          .collection('permission')
          .doc('admin')
          .collection('entry')
          .doc(userId)
          .get();
      } catch (error) {
        adminRoleQuery = null;
      }
      let instructorRoleQuery;
      try {
        instructorRoleQuery = await this.database
          .collection('permission')
          .doc('instructor')
          .collection('entry')
          .doc(userId)
          .get();
      } catch (error) {
        instructorRoleQuery = null;
      }
      let studentRoleQuery;
      try {
        studentRoleQuery = await this.database
          .collection('permission')
          .doc('student')
          .collection('entry')
          .doc(userId)
          .get();
      } catch (error) {
        studentRoleQuery = null;
      }

      try {
        const result = [adminRoleQuery, instructorRoleQuery, studentRoleQuery];
        const roleBooleanResults = result.map((doc) => {
          return doc?.exists;
        });

        let role: UserRole;

        if (roleBooleanResults[0]) {
          role = UserRole.ADMIN;
        } else if (roleBooleanResults[1]) {
          role = UserRole.INSTRUCTOR;
        } else if (roleBooleanResults[2]) {
          role = UserRole.STUDENT;
        } else {
          role = UserRole.INSTRUCTOR;
        }

        return new FirestoreServiceResult<UserRole>(role);
      } catch (error) {
        return {
          code: 'permission-denied',
          message: 'Permission Denied',
          name: 'Permission Error'
        } as FirestoreServiceError;
      }
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchStudent(
    id: string
  ): Promise<
    | FirestoreServiceResult<firebase.firestore.DocumentData>
    | FirestoreServiceError
  > {
    try {
      const query = await this.database.collection('student').doc(id).get();
      if (query.exists) {
        return new FirestoreServiceResult<firebase.firestore.DocumentData>(
          query
        );
      } else {
        return {
          code: 'not-found',
          message: 'Not Found',
          name: 'Not Found Error'
        } as FirestoreServiceError;
      }
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async setGradedEventCollection(
    studentId: string,
    events: GradedEvent[]
  ): Promise<FirestoreServiceResult<void[]> | FirestoreServiceError> {
    try {
      const promises = events.map((event) => {
        return this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(event.id)
          .set(classToPlain(event));
      });
      return new FirestoreServiceResult<void[]>(await Promise.all(promises));
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async setGradedEvent(
    studentId: string,
    event: GradedEvent
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      return new FirestoreServiceResult<void>(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(event.id)
          .set(classToPlain(event))
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async setGradedEventManeuver(
    studentId: string,
    eventId: string,
    gradedManeuver: GradedManeuver
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      return new FirestoreServiceResult<void>(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(eventId)
          .collection('maneuver')
          .doc(gradedManeuver.id)
          .update(gradedManeuver.toJson())
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async addGradedEventManeuver(
    studentId: string,
    eventId: string,
    maneuver: CreateGradedManeuverViewModel
  ): Promise<
    | FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >
    | FirestoreServiceError
  > {
    try {
      const plain = classToPlain(
        CreateGradedManeuver.fromCreateGradedManeuverViewModel(maneuver)
      );
      return new FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(eventId)
          .collection('maneuver')
          .add(plain)
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async deleteGradedEventManeuver(
    studentId: string,
    eventId: string,
    maneuver: ManeuverViewModel
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      return new FirestoreServiceResult<void>(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(eventId)
          .collection('maneuver')
          .doc(maneuver.id)
          .update({ deleted: true })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async deleteGradedEventManeuverCollection(
    studentId: string,
    maneuvers: ManeuverViewModel[]
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      const studentEventCollectionRef = this.database
        .collection('student')
        .doc(studentId)
        .collection('event');
      const transaction = await this.database.runTransaction(async (t) => {
        maneuvers.forEach((gradedManeuver) => {
          const maneuverDocRef = studentEventCollectionRef
            .doc(gradedManeuver.eventId)
            .collection('maneuver')
            .doc(gradedManeuver.id);
          t.update(maneuverDocRef, { deleted: true });
        });
      });
      return new FirestoreServiceResult<void>(transaction);
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async setGradedEventManeuverCollection(
    studentId: string,
    maneuvers: ManeuverViewModel[]
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      const studentEventCollectionRef = this.database
        .collection('student')
        .doc(studentId)
        .collection('event');
      const transaction = await this.database.runTransaction(async (t) => {
        maneuvers.forEach((gradedManeuver) => {
          const maneuverDocRef = studentEventCollectionRef
            .doc(gradedManeuver.eventId)
            .collection('maneuver')
            .doc(gradedManeuver.id);
          t.update(maneuverDocRef, gradedManeuver.toJson());
        });
      });
      return new FirestoreServiceResult<void>(transaction);
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async addGradedEventManeuverCollection(
    studentId: string,
    maneuvers: CreateGradedManeuverViewModel[]
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      const studentEventCollectionRef = this.database
        .collection('student')
        .doc(studentId)
        .collection('event');
      const transaction = await this.database.runTransaction(async (t) => {
        maneuvers.forEach((maneuver) => {
          const maneuverDocRef = studentEventCollectionRef
            .doc(maneuver.eventId)
            .collection('maneuver')
            .doc();
          const plain = classToPlain(
            CreateGradedManeuver.fromCreateGradedManeuverViewModel(maneuver)
          );
          t.set(maneuverDocRef, plain);
        });
      });

      return new FirestoreServiceResult<void>(transaction);
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async createInfrastructureIntegrationEvent(
    integrationEvent: any
  ): Promise<
    | FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >
    | FirestoreServiceError
  > {
    try {
      return new FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >(
        await this.database
          .collection('event')
          .doc('infrastructure')
          .collection('integrationEvents')
          .add(integrationEvent)
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async createStudentGradedEvent(
    studentId: string,
    event: GradedEvent
  ): Promise<
    | FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >
    | FirestoreServiceError
  > {
    try {
      return new FirestoreServiceResult<
        firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
      >(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .add(classToPlain(event))
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchStudentGradedEvents(
    studentId: string
  ): Promise<
    | FirestoreServiceResult<
        firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
      >
    | FirestoreServiceError
  > {
    try {
      return new FirestoreServiceResult<
        firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
      >(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .where('deleted', '!=', true)
          .get()
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchStudentEvent(
    studentId: string,
    eventId: string
  ): Promise<FirestoreServiceResult<GradedEvent> | FirestoreServiceError> {
    try {
      const studentEventDoc = await this.database
        .collection('student')
        .doc(studentId)
        .collection('event')
        .doc(eventId)
        .get();

      const maneuverCollection = await this.database
        .collection('student')
        .doc(studentId)
        .collection('event')
        .doc(eventId)
        .collection('maneuver')
        .where('deleted', '!=', true)
        .get();

      const maneuvers = maneuverCollection.docs.map((json) => {
        const data = json.data();
        return GradedManeuver.fromJson({
          id: json.id,
          comments: data.comments,
          correction: data.correction,
          grade: data.grade,
          mif: data.mif,
          name: data.name,
          reason: Reason.fromJson(data.reason),
          eventId
        });
      });
      const gradedEvent = GradedEvent.fromFirestoreSnapshot(
        { maneuvers },
        studentEventDoc
      );

      return new FirestoreServiceResult<GradedEvent>(gradedEvent);
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async fetchStudentGradedEventsFullyQualified(
    studentId: string,
    filterByPhase: string | null = null
  ): Promise<FirestoreServiceResult<GradedEvent[]> | FirestoreServiceError> {
    try {
      let query = this.database
        .collection('student')
        .doc(studentId)
        .collection('event')
        .where('deleted', '!=', true);

      if (filterByPhase) {
        query = query.where('phase', '==', filterByPhase);
      }

      const eventCollection = await query.get();

      const events = await Promise.all(
        eventCollection.docs.map(async (event) => {
          const maneuverCollection = await this.database
            .collection('student')
            .doc(studentId)
            .collection('event')
            .doc(event.id)
            .collection('maneuver')
            .where('deleted', '!=', true)
            .get();
          const maneuvers = maneuverCollection.docs.map((json) => {
            const data = json.data();
            return GradedManeuver.fromJson({
              id: json.id,
              comments: data.comments,
              correction: data.correction,
              grade: data.grade,
              mif: data.mif,
              name: data.name,
              reason: Reason.fromJson(data.reason),
              eventId: event.id
            });
          });
          return GradedEvent.fromFirestoreSnapshot({ maneuvers }, event);
        })
      );

      return new FirestoreServiceResult<GradedEvent[]>(events);
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }

  async deleteGradedEvent(
    studentId: string,
    event: GradedEvent
  ): Promise<FirestoreServiceResult<void> | FirestoreServiceError> {
    try {
      return new FirestoreServiceResult<void>(
        await this.database
          .collection('student')
          .doc(studentId)
          .collection('event')
          .doc(event.id)
          .update({ deleted: true })
      );
    } catch (error) {
      return {
        code: 'permission-denied',
        message: 'Permission Denied',
        name: 'Permission Error'
      } as FirestoreServiceError;
    }
  }
}
