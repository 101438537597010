import { MutationTree } from 'vuex';
import { StudentState } from './types';
import { GradedEvent, Recommendation, Student } from '@/models';
import { ResponseTypes } from '../../api/types';

export type MutationTypes =
  | 'SET_STUDENTS'
  | 'SET_STUDENT'
  | 'SET_STUDENT_EVENTS'
  | 'SET_STUDENT_EVENT'
  | 'SET_FILTER_BY_PHASE'
  | 'ADD_STUDENT_EVENTS'
  | 'SET_STUDENT_RECOMMENDATIONS'
  | 'ADD_STUDENT_RECOMMENDATION'
  | 'UPDATE_MANEUVER_GRADE'
  | 'SET_STUDENT_REQUEST'
  | 'SET_STUDENTS_REQUEST'
  | 'SET_STUDENT_EVENTS_REQUEST'
  | 'SET_STUDENT_RECOMMENDATIONS_REQUEST'
  | 'SET_IS_SAVING_EVENT'
  | 'SET_IS_SAVING_MANEUVER'
  | 'DELETE_STUDENT_EVENT'
  | 'ADD_MANEUVER_GRADE'
  | 'SET_CURRENT_COURSE';

export const mutations: MutationTree<StudentState> = {
  SET_STUDENTS(state, students: Student[]) {
    state.students = students;
  },
  SET_STUDENT(state, student: Student) {
    state.student = student;
  },
  SET_STUDENT_EVENTS(state, studentEvents: GradedEvent[]) {
    state.studentEvents = studentEvents;
  },
  SET_STUDENT_EVENT(state, studentEvent: GradedEvent) {
    state.studentEvent = studentEvent;
  },
  SET_FILTER_BY_PHASE(state, filterByPhase: string) {
    state.studentEventsQuery.filterByPhase = filterByPhase;
  },
  DELETE_STUDENT_EVENT(state, event: GradedEvent) {
    state.studentEvents.splice(
      state.studentEvents.findIndex((e) => e.id === event.id),
      1
    );
  },
  ADD_STUDENT_EVENTS(state, event: GradedEvent) {
    state.studentEvents.push(event);
  },
  SET_STUDENT_RECOMMENDATIONS(state, studentRecommendations: Recommendation[]) {
    state.studentRecommendations = studentRecommendations;
  },
  ADD_STUDENT_RECOMMENDATION(state, recommendation: Recommendation) {
    state.studentRecommendations.push(recommendation);
  },
  ADD_MANEUVER_GRADE(state, { maneuver, eventId }) {
    const existingEventIndex = state.studentEvents.findIndex(
      (e) => e.id === eventId
    );
    if (existingEventIndex !== -1) {
      const existingEvent = state.studentEvents[existingEventIndex];
      const maneuverIndex = existingEvent.maneuvers.findIndex(
        (m) => m.name === maneuver.name
      );
      if (maneuverIndex !== -1) {
        existingEvent.maneuvers[maneuverIndex].grade = maneuver.grade;
      }
    }
  },
  UPDATE_MANEUVER_GRADE(state, { event, maneuver }) {
    const existingEventIndex = state.studentEvents.findIndex(
      (e) => e === event
    );
    if (existingEventIndex !== -1) {
      const existingEvent = state.studentEvents[existingEventIndex];
      const maneuverIndex = existingEvent.maneuvers.findIndex(
        (m) => m.name === maneuver.name
      );
      if (maneuverIndex !== -1) {
        existingEvent.maneuvers.splice(maneuverIndex, 1, maneuver);
      }
    }
  },
  SET_STUDENT_REQUEST(state, request: ResponseTypes<Student>) {
    state.studentRequest = request;
  },
  SET_STUDENTS_REQUEST(state, request: ResponseTypes<Student[]>) {
    state.studentsRequest = request;
  },
  SET_STUDENT_EVENTS_REQUEST(state, request: ResponseTypes<GradedEvent[]>) {
    state.studentEventsRequest = request;
  },
  SET_STUDENT_RECOMMENDATIONS_REQUEST(
    state,
    request: ResponseTypes<Recommendation[]>
  ) {
    state.studentRecommendationsRequest = request;
  },
  SET_IS_SAVING_EVENT(state, isSavingEvent: boolean) {
    state.isSavingEvent = isSavingEvent;
  },
  SET_IS_SAVING_MANEUVER(state, isSavingManeuver: boolean) {
    state.isSavingManeuver = isSavingManeuver;
  }
};
