enum UserRole {
  GUEST,
  ADMIN,
  INSTRUCTOR,
  STUDENT
}

const UserRoleLabels = new Map<number, string>([
  [UserRole.GUEST, 'Guest'],
  [UserRole.ADMIN, 'Admin'],
  [UserRole.STUDENT, 'Student'],
  [UserRole.INSTRUCTOR, 'Instructor']
]);

export { UserRole, UserRoleLabels };
