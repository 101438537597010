import { createApp } from 'vue';
import App from '@/App.vue';
import { createSkylineRouter } from '@/router';
import { AuthClient } from '@/services/auth';
import store from '@/store';
import AuthRouteHandler from '@/router/AuthRouteHandler';
import { FontAwesomeIcon } from '@/plugins/font-awesome';
import '@algolia/autocomplete-theme-classic';
import { RoleRouteHandler } from './router/RoleRouteHandler';

const router = createSkylineRouter(store);

AuthClient.initialize();
AuthRouteHandler.initialize(store, router);
RoleRouteHandler.initialize(store, router);

createApp(App)
  .use(store)
  .use(router)
  .component('fa', FontAwesomeIcon)
  .mount('#app');

// TEST CHANGE - Norman Sharpe 6
