import { plainToClass } from 'class-transformer';

export default class Correction {
  display!: string;
  key!: string;

  // eslint-disable-next-line
  static fromJson(payload: { key: string; display: string }): Correction {
    return plainToClass(Correction, payload);
  }
}
