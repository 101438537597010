import { GetterTree } from 'vuex';
import { SyllabusState } from './types';
import { RootState } from '../types';
import { Chapter, Syllabus, Topic } from '@/models';
import Course from '../../models/Course';

export const getters: GetterTree<SyllabusState, RootState> = {
  currentCourse(state): Course {
    return state.currentCourse;
  },
  syllabus(state): Syllabus {
    return state.syllabus;
  },
  chapter(state): Chapter {
    return state.chapter;
  },
  topic(state): Topic {
    return state.topic;
  }
};
