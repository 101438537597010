import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { ClassState } from './types';
import { Api } from '@/api';
import Class from '../../models/Class';
import Course from '../../models/Course';

export const state: ClassState = {
  classMetrics: [],
  allClasses: [] as Class[],
  allCourses: [] as Course[],
  currentClass: {} as Class
};

export function create(api: Api): Module<ClassState, RootState> {
  const actions = createActions(api);
  const scheduleModule: Module<ClassState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return scheduleModule;
}
