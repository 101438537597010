import { Module } from 'vuex';
import { getters } from './getters';
import { createActions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import { SyllabusState } from './types';
import { Api } from '@/api';
import { Chapter, Syllabus, Topic } from '@/models';
import Course from '../../models/Course';

export const state: SyllabusState = {
  currentCourse: {} as Course,
  syllabus: {} as Syllabus,
  chapter: {} as Chapter,
  topic: {} as Topic
};

export function create(api: Api): Module<SyllabusState, RootState> {
  const actions = createActions(api);
  const applicantModule: Module<SyllabusState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
  return applicantModule;
}
