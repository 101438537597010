import { plainToClass } from 'class-transformer';

export default class Reason {
  key!: string;
  display!: string;

  // eslint-disable-next-line
  static fromJson(payload: { key: string; display: string }): Reason {
    return plainToClass(Reason, payload);
  }
}
