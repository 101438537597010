import {
  classToPlain,
  Exclude,
  plainToClass,
  Transform,
  TransformFnParams,
  Type
} from 'class-transformer';
import { CanonicalManeuver, GradedManeuver } from './Maneuver';
import { Maneuver } from './Maneuver';
import 'firebase/firestore';
import firebase from 'firebase/app';
// need to import reflect-metadata in order to use class-transformer in jest tests
// https://github.com/typestack/class-transformer/issues/52#issuecomment-292801534
import 'reflect-metadata';

export type Phase = 'preflight' | 'qualification' | 'mission';

export const EventPhases: { [key: string]: Phase } = {
  PREFLIGHT: 'preflight',
  QUALIFICATION: 'qualification',
  MISSION: 'mission'
};

export class GradedEvent {
  id!: string;
  name = '';
  title = '';
  phase!: Phase;
  category = '';
  medium = '';
  hours = 0;
  overallGrade = '1';
  overallComments = '';
  objectives = '';
  deleted = false;
  @Exclude({ toPlainOnly: true })
  loading = false;

  get complete(): boolean {
    return this.overallGrade !== '1';
  }

  @Type(() => Date)
  @Transform(
    (params: TransformFnParams) => {
      return params.obj[params.key].toDate();
    },
    { toClassOnly: true }
  )
  date: Date = new Date();
  maneuvers: GradedManeuver[] = [];

  // eslint-disable-next-line
  static fromJson(payload?: any): GradedEvent {
    return plainToClass(GradedEvent, payload);
  }

  // eslint-disable-next-line
  static toJson(payload: GradedEvent): any {
    return classToPlain(payload);
  }

  static fromFirestoreSnapshot(
    children: { maneuvers: GradedManeuver[] },
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
  ): GradedEvent {
    const data = snapshot.data();
    const payload: { maneuvers: GradedManeuver[]; id: string } = {
      ...data,
      maneuvers: children.maneuvers,
      id: snapshot.id
    };
    return plainToClass(GradedEvent, payload);
  }
}

export class Event {
  name = '';
  title = '';
  phase!: Phase;
  category = '';
  medium = '';
  hours = 0;
  maneuvers: Maneuver[] = [];
  prerequisites: string[] = [];
  description = '';
  deleted = false;
  objectives = '';

  // eslint-disable-next-line
  static fromJson(payload?: any): Event {
    return plainToClass(Event, payload);
  }
  // eslint-disable-next-line
  static toJson(payload: Event): any {
    return classToPlain(payload);
  }
}

export class CanonicalEvent {
  name = '';
  title = '';
  phase = '';
  category = '';
  medium = '';
  // hours: number | undefined = 0;
  maneuvers: CanonicalManeuver[] = [];

  // eslint-disable-next-line
  static fromJson(payload?: any): Event {
    return plainToClass(Event, payload);
  }
}
