import { RouteRecordRaw } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from '@/router/Routes';

export const generateRoutes: (store: Store<RootState>) => RouteRecordRaw[] = (
  store
) => [
  {
    path: '/admin',
    name: Routes.ADMIN,
    meta: {
      requiresAuth: true
    },
    async beforeEnter(to, from, next) {
      await store.dispatch('adminModule/getPublicInfrustructureJobs');
      next();
    },
    component: () => import('@/views/Admin/AdminDashboard.vue'),
    children: []
  }
];
