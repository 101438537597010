import { ActionTree } from 'vuex';
import { EventState } from './types';
import { RootState } from '../types';
import { Event, GradedEvent } from '@/models';
import { Api } from '@/api';

export function createActions(api: Api): ActionTree<EventState, RootState> {
  const actions: ActionTree<EventState, RootState> = {
    async fetchManeuvers({ commit }): Promise<Event[]> {
      const manuevers = await api.fetchManeuvers();
      commit('SET_MANEUVERS', manuevers);
      return manuevers;
    },
    async fetchSyllabusEvents(
      { commit },
      syllabusId: string
    ): Promise<Event[]> {
      const events = await api.fetchSyllabusEvents(syllabusId);
      commit('SET_SYLLABUS_EVENTS', events);
      return events;
    },
    async fetchSyllabusEvent(
      { commit },
      payload: {
        name: string;
        syllabusId: string;
      }
    ): Promise<Event> {
      const event = await api.fetchSyllabusEvent(
        payload.name,
        payload.syllabusId
      );
      commit('SET_SYLLABUS_EVENT', event);
      return event;
    },
    async fetchUnlockedEvents(
      { commit },
      payload: {
        gradedEvents: GradedEvent[];
        syllabusId: string;
      }
    ): Promise<Event[]> {
      const unlockedEvents = await api.fetchUnlockedStudentEvents(
        payload.gradedEvents,
        payload.syllabusId
      );
      commit('SET_UNLOCKED_EVENTS', unlockedEvents);
      return unlockedEvents;
    },
    async addScheduledEvent({ commit }, event): Promise<boolean> {
      commit('ADD_SCHEDULED_EVENT', event);
      return event;
    },
    async removeScheduledEvent({ commit }, event): Promise<boolean> {
      commit('REMOVE_SCHEDULED_EVENT', event);
      return event;
    },
    async fetchSyllabusAcademicEvents(
      { commit },
      syllabusId: string
    ): Promise<Event[]> {
      const events = await api.fetchSyllabusAcademicEvents(syllabusId);
      commit('SET_SYLLABUS_ACADEMIC_EVENTS', events);
      return events;
    }
  };
  return actions;
}
