import { plainToClass } from 'class-transformer';
import { Chapter } from './Chapter';

export class Syllabus {
  title = '';
  description = '';
  chapters: Chapter[] = [];

  // eslint-disable-next-line
  static fromJson(payload?: any): Syllabus {
    return plainToClass(Syllabus, payload);
  }
}
