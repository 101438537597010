import { MutationTree } from 'vuex';
import { ClassState } from './types';
import { ClassMetric } from '../../models/ClassMetrics';
import Class from '../../models/Class';
import Course from '../../models/Course';

export const mutations: MutationTree<ClassState> = {
  SET_CLASS_METRICS(state, classMetrics: ClassMetric[]) {
    state.classMetrics = classMetrics;
  },
  SET_ALL_CLASSES(state, classes: Class[]) {
    state.allClasses = classes;
  },
  SET_CURRENT_CLASS(state, classData: Class) {
    state.currentClass = classData;
  },
  SET_ALL_COURSES(state, courses: Course[]) {
    state.allCourses = courses;
  }
};
