import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { create as creatStudentModule } from './student';
import { create as creatEventModule } from './event';
import { create as creatUserModule } from './user';
import { create as creatSyllabusModule } from './syllabus';
import { create as creatClassModule } from './class';
import { create as creatCourseModule } from './course';
import { create as creatAdminModule } from './admin';
import axios from 'axios';
import { Api } from '@/api/index';
import { StudentApi } from '../api/StudentApi';
import FirestoreService from '../services/FirestoreService';
import StorageService from '../services/StorageService';

// This surely wont work in the cloud.
const axiosInstance = axios.create({
  baseURL: 'localhost:3000',
  timeout: 3000
});

const firestoreService = FirestoreService.getInstance();
const storageService = new StorageService();
const api = new Api(
  axiosInstance,
  new StudentApi(),
  firestoreService,
  storageService
);

const adminModule = creatAdminModule(api);
const studentModule = creatStudentModule(api);
const eventModule = creatEventModule(api);
const userModule = creatUserModule(api);
const syllabusModule = creatSyllabusModule(api);
const classModule = creatClassModule(api);
const courseModule = creatCourseModule(api);

const storeOptions: StoreOptions<RootState> = {
  modules: {
    adminModule,
    eventModule,
    studentModule,
    syllabusModule,
    userModule,
    classModule,
    courseModule
  }
};

const store = new Vuex.Store<RootState>(storeOptions);

export default store;
