import { MutationTree } from 'vuex';
import { AdminState } from './types';

export type MutationTypes = 'SET_PUBLIC_INFRUSTRUCTURE_JOBS';

export const mutations: MutationTree<AdminState> = {
  SET_PUBLIC_INFRUSTRUCTURE_JOBS(state, jobs) {
    state.publicInfrastructureJobs = jobs;
  }
};
