
import { defineComponent, ref } from 'vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import Icon from '@/components/icon/Icon';

export default defineComponent({
  components: {
    IconComponent
  },
  setup() {
    const searchText = ref();

    return {
      Icon,
      searchText
    };
  }
});
