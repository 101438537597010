/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Api, IApi } from './Api';

class StudentApi extends Api implements IApi {
  public constructor() {
    super(
      axios.create({
        baseURL:
          'localhost:5001/skyline-learning-dev/us-central1/bff/api/student',
        timeout: 10000
      })
    );
  }
}

export { StudentApi };
