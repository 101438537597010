import { ActionTree, Commit } from 'vuex';
import { SyllabusState } from './types';
import { RootState } from '../types';
import { Chapter, Syllabus, Topic } from '@/models';
import { Api } from '@/api';
import Course from '../../models/Course';
import { ErrorResponse, SuccessResponse } from '../../api/types';
import { MutationTypes } from '../student/mutations';

function typedCommit(
  commit: Commit,
  mutationType: MutationTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
) {
  return commit(mutationType, payload);
}

export function createActions(api: Api): ActionTree<SyllabusState, RootState> {
  const actions: ActionTree<SyllabusState, RootState> = {
    async fetchSyllabusDownloadURL(
      _,
      syllabusFileName: string
    ): Promise<string> {
      const syllabusURL = await api.fetchSyllabusDownloadURL(syllabusFileName);
      return syllabusURL;
    },
    async fetchSyllabus(
      { commit },
      syllabusFileName: string
    ): Promise<Syllabus> {
      const syllabus = await api.fetchSyllabus(syllabusFileName);
      commit('SET_SYLLABUS', syllabus);
      return syllabus;
    },
    async fetchChapter(
      { commit },
      { syllabus, name }
    ): Promise<Chapter | undefined> {
      const chapter = await api.fetchSyllabusChapter(syllabus, name);
      commit('SET_CHAPTER', chapter);
      return chapter;
    },
    async fetchTopic(
      { commit },
      { syllabus, chapterName, topicName }
    ): Promise<Topic | undefined> {
      const topic = await api.fetchSyllabusTopic(
        syllabus,
        chapterName,
        topicName
      );
      commit('SET_TOPIC', topic);
      return topic;
    }
  };
  return actions;
}
