import { GetterTree } from 'vuex';
import { CourseState } from './types';
import { RootState } from '../types';
import Course from '../../models/Course';

export const getters: GetterTree<CourseState, RootState> = {
  currentCourse(state): Course {
    return state.currentCourse;
  },
  allCourses(state): Course[] {
    return state.allCourses;
  }
};
