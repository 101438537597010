import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import firebase from 'firebase/app';
import { FirestoreServiceDocument } from '../services/FirestoreService';

export type ProgressMetrics = {
  total: number;
  completed: number;
  percentage: number;
};

export type Progress = {
  [key: string]: ProgressMetrics;
};

export type StudentProgress = {
  [key: string]: Progress;
};
export class Student {
  @Type(() => String)
  id!: string;
  firstName!: string;
  middleName!: string;
  lastName!: string;
  email!: string;
  @Type(() => Date)
  dateOfBirth!: Date;
  progress: StudentProgress = {};
  photoUrl!: string;
  historicalData!: {
    datasets: { data: number[]; label: string }[];
    labels: string[];
    rank_course: [
      {
        class: string;
        class_rank: { classRank: number; classSize: number } | string;
        course: string;
        follow_on_course: string;
        id: string;
      }
    ];
  };
  classId!: string;
  graduationProbability!: number;
  @Expose()
  @Transform(
    (value) => {
      return value.obj[value.key] || [];
    },
    { toClassOnly: true }
  )
  ridesRemaining!: number[];

  // eslint-disable-next-line
  static fromJson(payload?: any): Student {
    return plainToClass(Student, payload);
  }

  static fromFirestoreSnapshot(
    snapshot: firebase.firestore.DocumentData
  ): Student {
    const student = this.fromJson({
      id: snapshot.id,
      ...snapshot.data()
    });
    return student;
  }
  static fromFirestoreServiceResult(
    snapshot: FirestoreServiceDocument
  ): Student {
    const student = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    return student;
  }

  getPhaseProgress(): Progress {
    return this.progress['phase'];
  }
}
