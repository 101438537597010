import { plainToClass } from 'class-transformer';

export class ClassMetric {
  phase!: string;
  className!: string;
  totalStudents!: number;
  avgGrade!: string;
  status!: string;
  dnif!: number;

  // eslint-disable-next-line
  static fromJson(payload?: any): ClassMetric {
    return plainToClass(ClassMetric, payload);
  }
}
