import {
  classToPlain,
  Exclude,
  Expose,
  plainToClass,
  Type
} from 'class-transformer';
import Correction from './Correction';
import Reason from './Reason';
import { Mif } from './Mif';
import Icon from '../components/icon/Icon';
import { GradeIconMap } from './GradeIconMap';

export class CreateGradedManeuver {
  name!: string;
  mif!: Mif;
  grade = 'NG';
  reason!: Reason;
  correction!: Correction;
  comments!: string;
  deleted = false;

  // eslint-disable-next-line
  static fromJson(payload?: any): CreateGradedManeuver {
    return plainToClass(CreateGradedManeuver, payload);
  }

  static fromCreateGradedManeuverViewModel(
    createGradedManeuverViewModel: CreateGradedManeuverViewModel
  ): CreateGradedManeuver {
    const payload: CreateGradedManeuver = {
      comments: createGradedManeuverViewModel.comments,
      mif: createGradedManeuverViewModel.mif,
      correction: createGradedManeuverViewModel.correction,
      name: createGradedManeuverViewModel.name,
      reason: createGradedManeuverViewModel.reason,
      deleted: false,
      grade:
        createGradedManeuverViewModel.grade === ''
          ? 'NG'
          : createGradedManeuverViewModel.grade
    };
    return plainToClass(CreateGradedManeuver, payload);
  }
}
export class GradedManeuver {
  id!: string;
  name!: string;
  mif!: Mif;
  grade = '';
  reason!: Reason;
  correction!: Correction;
  comments!: string;
  eventId!: string;
  @Exclude({ toPlainOnly: true })
  loading = false;

  static _type = 'GradedManeuver';

  static fromJson(json: {
    id: string;
    name: string;
    mif: Mif;
    grade: string;
    // reasonKey: string;
    reason: Reason;
    correction: Correction;
    comments: string;
    eventId: string;
  }): GradedManeuver {
    return plainToClass(GradedManeuver, json);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJson(): Record<string, any> {
    return classToPlain<GradedManeuver>(this);
  }

  @Expose({ toPlainOnly: true })
  get editable(): boolean {
    return this.mif.value !== 'NONE';
  }

  @Expose()
  get type(): string {
    return GradedManeuver._type;
  }

  @Expose()
  get icon(): Icon {
    if (!this.editable) {
      return Icon.NONE;
    }
    return GradeIconMap.get(this.grade) || Icon.BULLSEYE;
  }
}

export class Maneuver {
  name = '';
  description?: string = '';
  mif!: Mif;
  formattedManeuver!: string;

  // eslint-disable-next-line
  static fromJson(payload?: any): Maneuver {
    return plainToClass(Maneuver, payload);
  }
}

export class CanonicalManeuver {
  name!: string;
  mif!: {
    value: string;
    formattedManeuver: string;
    unFormattedManeuver: string;
  };

  // eslint-disable-next-line
  static fromJson(payload?: any): CanonicalManeuver {
    return plainToClass(CanonicalManeuver, payload);
  }
}

export class ManeuverViewModel {
  id!: string;
  name!: string;
  mif!: Mif;
  reasonKey!: string;
  reasonDisplay!: string;
  correction!: Correction;
  comments!: string;
  eventId!: string;

  private static _type = 'ManeuverViewModel';

  @Expose()
  get type(): string {
    return ManeuverViewModel._type;
  }

  static fromJson(payload: {
    id: string;
    name: string;
    mif: Mif;
    reasonKey: string;
    reasonDisplay: string;
    correction: Correction;
    comments: string;
    eventId: string;
  }): ManeuverViewModel {
    return plainToClass(ManeuverViewModel, payload);
  }

  static fromGradedManeuver(gradedManeuver: GradedManeuver): ManeuverViewModel {
    return plainToClass(ManeuverViewModel, gradedManeuver);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJson(): Record<string, any> {
    return classToPlain<ManeuverViewModel>(this);
  }

  @Expose()
  get editable(): boolean {
    return this.mif.value !== 'NONE';
  }

  @Expose()
  get icon(): Icon {
    let icon: Icon = Icon.PLAN;
    if (!this.editable) {
      icon = Icon.NONE;
    }
    return icon;
  }
}

export class CreateGradedManeuverViewModel {
  name!: string;
  mif!: Mif;
  reason = Reason.fromJson({ key: '', display: '' });
  correction = Correction.fromJson({ key: '', display: '' });
  comments = '';
  grade = '';
  eventId!: string;

  static _type = 'CreateGradedManeuverViewModel';

  @Expose()
  get type(): string {
    return CreateGradedManeuverViewModel._type;
  }

  static fromJson(json: {
    name: string;
    mif: Mif;
    reason?: Reason;
    correction?: Correction;
    comments?: string;
    grade?: string;
    eventId: string;
  }): CreateGradedManeuverViewModel {
    return plainToClass(CreateGradedManeuverViewModel, json);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJson(): Record<string, any> {
    return classToPlain<CreateGradedManeuverViewModel>(this);
  }

  @Type(() => Boolean)
  @Expose({ toPlainOnly: true })
  get editable(): boolean {
    return this.mif.value !== 'NONE';
  }

  @Expose({ toPlainOnly: true })
  get icon(): Icon {
    if (!this.editable) {
      return Icon.NONE;
    }
    return Icon.CHEVRON_DOWN;
  }
}
