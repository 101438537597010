import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { RouteRecordRaw } from 'vue-router';
import { Store } from 'vuex';

export const generateRoutes: (store: Store<RootState>) => RouteRecordRaw[] = (
  store
) => [
  {
    path: '/syllabus/',
    name: Routes.SYLLABUS,
    meta: {
      requiresAuth: true
    },
    async beforeEnter(to, from, next) {
      await store.dispatch('classModule/fetchCourses');
      next();
    },
    component: () => import('@/views/Syllabus/SyllabusPage.vue'),
    props: () => {
      return {
        syllabus: store.getters['syllabusModule/syllabus'],
        classList: store.getters['classModule/allClasses'],
        courseList: store.getters['classModule/allCourses']
      };
    },
    children: [
      {
        path: 'chapter/:chapterName',
        name: Routes.SYLLABUS_CHAPTER_DETAILS,
        component: () =>
          import('@/views/Syllabus/SyllabusChapterDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          if (from.path === `/${Routes.LOGIN}`) {
            next(Routes.SYLLABUS);
          }
          await store.dispatch('syllabusModule/fetchChapter', {
            name: to.params.chapterName,
            syllabus: store.getters['syllabusModule/syllabus']
          });
          next();
        },
        props: () => {
          return {
            chapter: store.getters['syllabusModule/chapter']
          };
        }
      },
      {
        path: 'event/:eventName',
        name: Routes.SYLLABUS_EVENT_DETAILS,
        component: () =>
          import('@/views/Syllabus/SyllabusEventDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          if (from.path === `/${Routes.LOGIN}`) {
            next(Routes.SYLLABUS);
          }
          if (from.path.includes('progress')) {
            await store.dispatch('classModule/fetchCourses');
            const currentCourseSyllabusId =
              store.getters['courseModule/currentCourse'].syllabusOptions[0]
                .downloadId;
            await store.dispatch(
              'syllabusModule/fetchSyllabus',
              currentCourseSyllabusId
            );
          }
          await store.dispatch('eventModule/fetchSyllabusEvent', {
            name: to.params.eventName,
            syllabusId:
              store.getters['courseModule/currentCourse'].syllabusOptions[0]
                .downloadId
          });
          next();
        },
        props: () => {
          return {
            event: store.getters['eventModule/syllabusEvent']
          };
        }
      },
      {
        path: 'chapter/:chapterName/topic/:topicName/event/:eventName',
        name: Routes.SYLLABUS_TOPIC_EVENT_DETAILS,
        component: () =>
          import('@/views/Syllabus/SyllabusEventDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          if (from.path === `/${Routes.LOGIN}`) {
            next(Routes.SYLLABUS);
          }
          await store.dispatch('eventModule/fetchSyllabusEvent', {
            name: to.params.eventName,
            syllabusId: store.getters['courseModule/currentCourse'].downloadId
          });
          next();
        },
        props: () => {
          return {
            event: store.getters['eventModule/syllabusEvent']
          };
        }
      },
      {
        path: 'chapter/:chapterName/topic/:topicName',
        name: Routes.SYLLABUS_TOPIC_DETAILS,
        component: () =>
          import('@/views/Syllabus/SyllabusTopicDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          if (from.path === `/${Routes.LOGIN}`) {
            next(Routes.SYLLABUS);
          }
          await store.dispatch('syllabusModule/fetchTopic', {
            syllabus: store.getters['syllabusModule/syllabus'],
            chapterName: to.params.chapterName,
            topicName: to.params.topicName
          });
          next();
        },
        props: () => {
          return {
            topic: store.getters['syllabusModule/topic'],
            course: store.getters['classModule/allCourses']
          };
        }
      }
    ]
  }
];
