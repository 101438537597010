import { RouteRecordRaw } from 'vue-router';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import Routes from '@/router/Routes';
import { GradedEvent } from '../models';
import { UserRole } from '../models/UserRoles';

export const generateRoutes: (store: Store<RootState>) => RouteRecordRaw[] = (
  store
) => [
  {
    path: '/student',
    name: Routes.STUDENT,
    meta: {
      requiresAuth: true
    },
    component: () => import('@/views/Student/StudentPage.vue'),
    children: [
      {
        path: '',
        name: Routes.STUDENT_DASHBOARD,
        component: () => import('@/views/Student/StudentDashboardPage.vue'),
        async beforeEnter(to, from, next) {
          await store.dispatch('studentModule/fetchAll');
          await store.dispatch('classModule/fetchClassMetrics');
          await store.dispatch('classModule/fetchClasses');
          next();
        },
        props: () => {
          return {
            classes: store.getters['classModule/allClasses'],
            students: store.getters['studentModule/students'],
            classMetrics: store.getters['classModule/classMetrics']
          };
        }
      },
      {
        path: ':id',
        name: Routes.STUDENT_DETAILS,
        component: () => import('@/views/Student/StudentDetailsPage.vue'),
        async beforeEnter(to, from, next) {
          await store.dispatch('studentModule/fetch', to.params.id);
          const student = store.getters['studentModule/student'];
          await store.dispatch('classModule/setCurrentClass', student.classId);
          next();
        },
        props: () => {
          return {
            student: store.getters['studentModule/student'],
            classData: store.getters['classModule/currentClass']
          };
        },

        children: [
          {
            path: 'progress',
            name: Routes.STUDENT_PROGRESS,
            async beforeEnter(to, from, next) {
              await store.dispatch('studentModule/fetch', to.params.id);
              const student = store.getters['studentModule/student'];
              await store.dispatch(
                'classModule/setCurrentClass',
                student.classId
              );
              const classData = store.getters['classModule/currentClass'];
              await store.dispatch(
                'eventModule/fetchSyllabusEvents',
                classData.syllabusUrl
              );
              const gradedEvents: GradedEvent[] = await store.dispatch(
                'studentModule/fetchStudentGradedEventsFullyQualified',
                { studentId: to.params.id }
              );
              await store.dispatch('eventModule/fetchUnlockedEvents', {
                gradedEvents,
                syllabusId: classData.syllabusUrl
              });
              next();
            },
            props: () => {
              return {
                events: store.getters['eventModule/syllabusEvents'],
                studentEvents: store.getters['studentModule/studentEvents'],
                unlockedEvents: store.getters['eventModule/unlockedEvents'],
                classData: store.getters['classModule/currentClass'],
                student: store.getters['studentModule/student']
              };
            },

            component: () => import('@/views/Student/StudentProgressPage.vue')
          },
          {
            path: 'events',
            name: Routes.STUDENT_EVENTS,
            component: () => import('@/views/Student/StudentEventsPage.vue')
          },
          {
            path: 'skillgraph',
            name: Routes.STUDENT_SKILL_GRAPH,
            component: () => import('@/views/Student/StudentSkillGraphPage.vue')
          },
          {
            path: 'events/:eventId',
            name: Routes.STUDENT_EVENT_DETAILS,
            component: () =>
              import('@/views/Student/StudentEventDetailsPage.vue'),
            async beforeEnter(to, from, next) {
              const event = await store.dispatch('studentModule/fetchEvent', {
                studentId: to.params.id,
                eventId: to.params.eventId
              });
              const student = store.getters['studentModule/student'];
              await store.dispatch(
                'classModule/setCurrentClass',
                student.classId
              );
              const classData = store.getters['classModule/currentClass'];
              await store.dispatch('eventModule/fetchSyllabusEvent', {
                name: event.name,
                syllabusId: classData.syllabusUrl
              });
              next();
            },
            props: () => {
              return {
                student: store.getters['studentModule/student'],
                gradedEvent: store.getters['studentModule/studentEvent'],
                event: store.getters['eventModule/syllabusEvent'],
                readonly:
                  store.getters['userModule/currentUser'].role ===
                  UserRole.STUDENT
              };
            }
          },
          {
            path: 'projections',
            name: Routes.STUDENT_PROJECTIONS,
            component: () => import('@/views/Student/StudentProjections.vue'),
            props: () => {
              return {
                student: store.getters['studentModule/student']
              };
            }
          }
        ]
      }
    ]
  }
];
