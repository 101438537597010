import { GetterTree } from 'vuex';
import { StudentState } from './types';
import { RootState } from '../types';
import { GradedEvent, Recommendation, Student } from '@/models';
import { ResponseTypes } from '../../api/types';

export const getters: GetterTree<StudentState, RootState> = {
  students(state): Student[] {
    return state.students;
  },
  student(state): Student {
    return state.student;
  },
  studentEvents(state): GradedEvent[] {
    return state.studentEvents;
  },
  studentEvent(state): GradedEvent {
    return state.studentEvent;
  },
  studentRecommendations(state): Recommendation[] {
    return state.studentRecommendations;
  },
  studentRequest(state): ResponseTypes<Student> {
    return state.studentRequest;
  },
  studentsRequest(state): ResponseTypes<Student[]> {
    return state.studentsRequest;
  },
  studentEventsRequest(state): ResponseTypes<GradedEvent[]> {
    return state.studentEventsRequest;
  },
  studentRecommendationsRequest(state): ResponseTypes<Recommendation[]> {
    return state.studentRecommendationsRequest;
  },
  isSavingEvent(state): boolean {
    return state.isSavingEvent;
  },
  isSavingManeuver(state): boolean {
    return state.isSavingManeuver;
  }
};
