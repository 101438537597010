import { ActionTree } from 'vuex';
import { UserState } from './types';
import { RootState } from '../types';
import { User } from '@/models/User';
import firebase from 'firebase';
import { AuthClient } from '@/services/auth';
import { Api } from '@/api';
import { SuccessResponse } from '../../api/types';
import { UserRole } from '../../models/UserRoles';

export function createActions(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  api: Api
): ActionTree<UserState, RootState> {
  const actions: ActionTree<UserState, RootState> = {
    async logout({ commit }) {
      commit('SET_USER', null);
      commit('SET_IS_LOGGED_IN', false);
      commit('SET_IS_EMAIL_VERIFIED', false);
      commit('SET_FIREBASE_USER', null);
    },
    async login({ commit }, auth: firebase.User) {
      const authUser = User.fromAuthClient(auth);
      const userRole = (await api.fetchUserRole(
        authUser.uid
      )) as SuccessResponse<UserRole>;
      const verified = await AuthClient.getInstance().isEmailVerified();
      commit('SET_FIREBASE_USER', auth);
      commit('SET_IS_LOGGED_IN', true);
      commit('SET_IS_EMAIL_VERIFIED', verified);
      commit('SET_USER', {
        ...authUser,
        role: userRole.data
      });
    },
    async updateSkyHubUser({ commit }, user: User) {
      //  await bff.updateSkyHubUser(user);
      commit('SET_USER', user);
    },
    // async updateSkyHubUserRole(_, user: User) {
    async updateSkyHubUserRole() {
      //   await bff.updateSkyHubUser(user);
    }
  };
  return actions;
}
