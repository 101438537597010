import { plainToClass } from 'class-transformer';
import { FirestoreServiceDocument } from '../services/FirestoreService';

export default class Class {
  id!: string;
  name!: string;
  startDate!: string;
  endDate!: string;
  studentIds!: string[];
  syllabusUrl!: string;
  courseId!: string;

  constructor() {
    this.id = '';
    this.name = 'no class name';
    this.startDate = 'no class start date';
    this.endDate = 'no class end date';
    this.studentIds = [];
    this.syllabusUrl = 'no syllabus url';
    this.courseId = 'no course id';
  }

  // eslint-disable-next-line
  static fromJson(payload?: any): Class {
    return plainToClass(Class, payload);
  }

  static fromFirestoreServiceResult(snapshot: FirestoreServiceDocument): Class {
    const classData = this.fromJson({
      id: snapshot.id,
      ...snapshot.data
    });
    return classData;
  }
}
